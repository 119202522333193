import React, { useState, useCallback, createContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import * as Types from './types';

interface ContextValue {
  activeIndex: number;
  onBack: () => void;
  onNext: () => void;
  onSetCurrentPage: (value: number) => void;
}

const HeaderContext = createContext<ContextValue>({} as ContextValue);

const HeaderProvider = ({ children }: Types.ProviderProps) => {
  const navigation = useNavigation();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const onBack = useCallback(() => {
    if (activeIndex === 0 || activeIndex === 5 || activeIndex === 6) {
      // navigation.goBack();
      navigation.navigate('Home');
      return;
    }
    setActiveIndex(activeIndex - 1);
  }, [activeIndex]);

  const onNext = useCallback(() => {
    setActiveIndex(activeIndex + 1);
  }, [activeIndex]);

  const onSetCurrentPage = useCallback(
    (value: number) => {
      setActiveIndex(value);
    },
    [activeIndex]
  );

  const value = {
    activeIndex,
    onBack,
    onNext,
    onSetCurrentPage,
  };

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export { HeaderProvider, HeaderContext };
