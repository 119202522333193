import React from 'react';
import { HeaderProvider } from './provider/header';
import { WitnessProvider } from './provider/witness';
import { DocumentProvider } from './provider/document';
import { WitnessScreenProvider } from './witness-signature/provider/screen';
import { PluginProvider } from './witness-signature/provider/plugin';
import { CanvasProvider } from './witness-signature/provider/canvas';

import PDFPages from './witness-signature/pages';
import useStyles from './styles.css';
import { View } from 'react-native';
import Screens from './screens';
import Header from './header';

const LiveRequest = () => {
  const styles = useStyles();

  return (
    <HeaderProvider>
      <WitnessProvider>
        <DocumentProvider>
          <PluginProvider>
            <CanvasProvider>
              <WitnessScreenProvider>
                <View style={styles.main}>
                  <Header />
                  <Screens />
                  <PDFPages />
                </View>
              </WitnessScreenProvider>
            </CanvasProvider>
          </PluginProvider>
        </DocumentProvider>
      </WitnessProvider>
    </HeaderProvider>
  );
};

export default LiveRequest;
