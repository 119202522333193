import React from 'react';
import { mobileSizeChecker } from '@src/utils/screensize-helper';
import { View, Text, Image } from 'react-native';
import useStyles from './styles.css';
import images from '@assets/images';

const Header = () => {
  const styles = useStyles();
  const isMobile = mobileSizeChecker();

  return (
    <View style={styles.container}>
      <Image
        style={styles.i_notary}
        source={images.enotary_horizontal}
        resizeMode="contain"
      />
      {isMobile && (
        <>
          <Text style={styles.t_notary_notes}>Remote notarization</Text>
          <Text style={styles.t_notary_notes}>at your fingertips</Text>
        </>
      )}
    </View>
  );
};

export default Header;
