import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.transparent,
    },
    v_center_blur: {
      flex: 1,
      zIndex: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: colors.blurBG2,
    },
    v_center: {
      width: 600,
      height: 790,
      zIndex: 2,
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.white,
    },
    t_face_recog: {
      fontFamily: 'DMSans-Bold',
      fontSize: 24,
      lineHeight: 32,
      textAlign: 'center',
      color: colors.black2,
    },
    t_fr_sub: {
      fontFamily: 'DMSans',
      fontSize: 14,
      lineHeight: 32,
      textAlign: 'center',
      color: colors.black2,
    },
    v_frame_center: {
      paddingVertical: 40,
      alignItems: 'center',
      justifyContent: 'center',
    },
    i_frame: {
      width: 335,
      height: 440,
    },
    btn_style: {
      minWidth: 300,
    },
  },
  {
    [DEVICE_SIZES.XL]: {
      v_center: { width: 600, height: 790 },
    },
    [DEVICE_SIZES.LG]: {
      v_center: { width: 580, height: 670 },
      i_frame: {
        height: 380,
      },
    },
    [DEVICE_SIZES.MD]: {
      v_center: { width: 550, height: 650 },
      i_frame: {
        height: 380,
      },
    },

    [DEVICE_SIZES.SM]: {
      v_center: { width: '100%', height: 670 },
      i_frame: { width: 300, height: 380 },
    },
    [DEVICE_SIZES.XS]: {
      v_center: { width: '100%', height: 670 },
      i_frame: { width: 300, height: 380 },
    },
  }
);

export default useStyles;
