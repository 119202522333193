import React, { useContext, useState } from 'react';
import { View, Text } from 'react-native';
import { DrawerContext } from '../DrawerProvider';
import { DrawerComponentContext } from "./provider";
import { DEVICE_SIZES, useDeviceSize } from "rn-responsive-styles";
import DrawerItem from "../drawer-component/drawer-list/drawer-item";
import useStyles from './styles.css';
import { AdminList } from '@src/utils/mockdata/AdminList';
import { useAppSelector } from '@src/ducks/ducksHook';
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';

const DrawerList = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();
  const { drawerList, activeTab, onActiveTab, onActiveTabDrawer } = useContext(DrawerContext);
  const { onMinimizeState, isMinimize } = useContext(DrawerComponentContext);
  const [hover, setHover] = useState(
    new Array<boolean>(drawerList.length).fill(false)
  );
  const role = useAppSelector(selectedAuthRole);

  const handleHover = React.useCallback((eventName: string, idx: number) => {
    const isHover = eventName === "hoverIn";
    setHover((prevState) => ({
      ...prevState,
      [idx]: isHover,
    }));
  },[]);

  const onToggle = (itemName : string) => {
    if (DEVICE_SIZES.XS === device_size || DEVICE_SIZES.SM === device_size || DEVICE_SIZES.MD === device_size) {
      onMinimizeState();
    }
    onActiveTab(itemName);
  };

  if (role !== 'admin') {
    return null;
  }
  
  return (
    <View style={[styles.v_margintop, styles.v_padding_bottom]}>
      {!isMinimize && <Text style={styles.adminTabs}>Admin Menu</Text> }
      {AdminList.map((label: string, index: number) => {
        const isHover = hover[index];
        const isActive = label === activeTab;

        return (
          <>
            <DrawerItem
              key={`tabs${index}`}
              isActive={isActive}
              isHover={isHover}
              item={label}
              onPress={() => onToggle(label)}
              onPressIn={() => handleHover("hoverIn", index)}
              onPressOut={() => handleHover("hoverOut", index)}
              onHoverIn={() => handleHover("hoverIn", index)}
              onHoverOut={() => handleHover("hoverOut", index)}
            />
          </>
        );
      })}
    </View>
  );
};

export default DrawerList;
