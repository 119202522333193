import React from "react";
import { DrawerComponentProvider } from "./provider";
import DrawerComponentScreen from "./screen";

const DrawerComponent = () => {

  return (
    <DrawerComponentProvider>
      <DrawerComponentScreen />
    </DrawerComponentProvider>
  );
};

export default DrawerComponent;