import React, { useContext } from 'react';
import { RenderZoomOutProps } from '@react-pdf-viewer/zoom';
import { CanvasContext } from '../provider/canvas';
import { PluginContext } from '../provider/plugin';
import { Button02 } from '@src/components/button';
import { Feather } from '@expo/vector-icons';
import useStyles from './styles.css';
import { View } from 'react-native';
import colors from '@assets/colors';

const PDFZoom = () => {
  const styles = useStyles();
  const { canvas } = useContext(CanvasContext);
  const { zoomPluginInstance } = useContext(PluginContext);
  const { ZoomIn, ZoomOut } = zoomPluginInstance;

  return (
    <View style={styles.zoom_container}>
      <ZoomIn>
        {({ onClick }: RenderZoomOutProps) => (
          <Button02
            disabled={canvas.scale > 1.7}
            onPress={onClick}
            style={styles.pdf_btn_zoom}
          >
            <Feather
              name="zoom-in"
              color={canvas.scale > 1.7 ? colors.blurBG : colors.purple}
              size={20}
            />
          </Button02>
        )}
      </ZoomIn>
      <View style={styles.divider} />
      <ZoomOut>
        {({ onClick }: RenderZoomOutProps) => (
          <Button02
            disabled={canvas.scale < 0.7}
            onPress={onClick}
            style={styles.pdf_btn_zoom}
          >
            <Feather
              name="zoom-out"
              color={canvas.scale < 0.7 ? colors.blurBG : colors.purple}
              size={20}
            />
          </Button02>
        )}
      </ZoomOut>
    </View>
  );
};

export default React.memo(PDFZoom);
