import React from 'react';
import { useDeviceSize, DEVICE_SIZES } from 'rn-responsive-styles';
import { View, Text, Image } from 'react-native';
import useStyles from './styles.css';
import images from '@assets/images';

const Header = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();

  if (DEVICE_SIZES.XS !== device_size && DEVICE_SIZES.SM !== device_size && DEVICE_SIZES.MD !== device_size) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Image
        style={styles.i_notary}
        source={images.enotary_horizontal}
        resizeMode="contain"
      />
      <Text style={styles.t_notary_notes}>Remote notarization</Text>
      <Text style={styles.t_notary_notes}>at your fingertips</Text>
    </View>
  );
};

export default Header;
