import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../ducksHook';
import {
  signupActions,
  selectedSigningIn,
  selectedSigningData,
  selectedSigningFailed,
} from '../slices/signup.slice';

// Types
import { SignupInput, ErrorValue } from '../types';

type Operators = {
  data: {};
  failed: ErrorValue;
  isLoading: boolean;
  onSignup: (params: SignupInput) => void;
};

// 1 .Dispatch
export const useSignupService = (): Readonly<Operators> => {
  const dispatch = useAppDispatch();

  return {
    data: useAppSelector(selectedSigningData),
    failed: useAppSelector(selectedSigningFailed),
    isLoading: useAppSelector(selectedSigningIn),
    onSignup: useCallback(
      (params: SignupInput) => {
        dispatch(signupActions.signupRequest(params));
      },
      [dispatch]
    ),
  };
};

export default useSignupService;
