import { useCallback } from 'react';
import { useAppDispatch } from '../ducksHook';
import { signsecureActions } from '../slices/signsecure.slice';

interface Operators {
  viewDocumentRequest: (value: any) => void;
  refreshDocumentRequest: () => void;
  createLiveRequest: (value: any) => void;
  signDocumentRequest: () => void;
  updateSignatures: (value: any) => void;
  resetError: () => void;
}

export const useSignsecureService = (): Readonly<Operators> => {
  const dispatch = useAppDispatch();

  return {
    createLiveRequest: useCallback((value) => {
      dispatch(signsecureActions.createLiveRequest(value));
    }, [dispatch]),
    viewDocumentRequest: useCallback((value) => {
      dispatch(signsecureActions.viewDocumentRequest(value));
    }, [dispatch]),
    refreshDocumentRequest: useCallback(() => {
      dispatch(signsecureActions.refreshDocumentRequest());
    }, [dispatch]),
    signDocumentRequest: useCallback(() => {
      dispatch(signsecureActions.affixSignatureRequest());
    }, [dispatch]),
    updateSignatures: useCallback((value) => {
      dispatch(signsecureActions.updateSignatures(value));
    }, [dispatch]),
    resetError: useCallback(() => {
      dispatch(signsecureActions.resetError());
    }, [dispatch]),
  };
};

export default useSignsecureService;
