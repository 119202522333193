import moment from 'moment';

const DOCSTAMP = [
  {
    id: 'wxtbFB5KFrCG00-gntQS6',
    trackId: "notary",
    "type": "signature-notary",
    "config": {
      "fill": "#000000",
      "x": 210.5208333333333,
      "y": 500.3333333333333,
      "width": 440,
      "height": 220,
      "rotation": 0,
      "stroke": "#000000",
      "strokeWidth": "1"
    },
  },
  {
    id: '9aqqUpoIlW1OqczK-pNYO1',
    trackId: "notary",
    type: 'doc-stamp',
    "config": {
      "fill": "#000000",
      x: 73.94791666666667,
      y: 538,
      "width": 130,
      "height": 14,
      "rotation": 0,
      "stroke": "#000000",
      "strokeWidth": "1"
    },
    "nameConfig": {
      x: 73.94791666666667,
      y: 538,
      text: `WITNESS MY HAND AND SEAL this ${moment(Date.now()).format('MMMM DD, YYYY')} at Bacolod City, Philippines.`,
      "fontSize": 10,
      "width": 130,
      "fontFamily": "DMSans",
      "fill": "#000000"
    }
  },
  {
    id: '9aqqUpoIlW1OqczK-pNYO',
    trackId: "notary",
    type: 'doc-stamp',
    "config": {
      "fill": "#000000",
      x: 73.94791666666667,
      y: 571.3333333333334,
      "width": 130,
      "height": 14,
      "rotation": 0,
      "stroke": "#000000",
      "strokeWidth": "1"
    },
    "nameConfig": {
      x: 73.94791666666667,
      y: 571.3333333333334,
      text: 'NOTARY PUBLIC',
      "fontSize": 10,
      "width": 130,
      "fontFamily": "DMSans",
      "fill": "#000000"
    }
  },
  {
    id: 'wrjztDQFlgRIO14953tBS',
    trackId: "notary",
    type: 'doc-stamp',
    "config": {
      "fill": "#000000",
      x: 73.94791666666667,
      y: 604.6666666666666,
      "width": 130,
      "height": 14,
      "rotation": 0,
      "stroke": "#000000",
      "strokeWidth": "1"
    },
    "nameConfig": {
      x: 73.94791666666667,
      y: 604.6666666666666,
      text: 'Doc No. 1;',
      "fontSize": 10,
      "width": 130,
      "fontFamily": "DMSans",
      "fill": "#000000"
    }
  },
  {
    id: 'tKJQFjKmS_tEjllv49KuI',
    trackId: "notary",
    type: 'doc-stamp',
    "config": {
      "fill": "#000000",
      x: 73.94791666666667,
      y: 638.6666666666666,
      "width": 130,
      "height": 14,
      "rotation": 0,
      "stroke": "#000000",
      "strokeWidth": "1"
    },
    "nameConfig": {
      x: 73.94791666666667,
      y: 638.6666666666666,
      text: 'Page No. 1;',
      "fontSize": 10,
      "width": 130,
      "fontFamily": "DMSans",
      "fill": "#000000"
    }
  },
  {
    id: '5_8JK-P8vsn7rVRGz9sLK',
    trackId: "notary",
    type: 'doc-stamp',
    "config": {
      "fill": "#000000",
      x: 73.94791666666667,
      y: 671.3333333333334,
      "width": 130,
      "height": 14,
      "rotation": 0,
      "stroke": "#000000",
      "strokeWidth": "1"
    },
    "nameConfig": {
      x: 73.94791666666667,
      y: 671.3333333333334,
      text: 'Book No. I',
      "fontSize": 10,
      "width": 130,
      "fontFamily": "DMSans",
      "fill": "#000000"
    }
  },
  {
    id: 'CGIKd4h_h3ta94D9Dj_zs',
    trackId: "notary",
    type: 'doc-stamp',
    "config": {
      "fill": "#000000",
      x: 73.94791666666667,
      y: 706,
      "width": 130,
      "height": 14,
      "rotation": 0,
      "stroke": "#000000",
      "strokeWidth": "1"
    },
    "nameConfig": {
      x: 73.94791666666667,
      y: 706,
      text: 'Series of 2024;',
      "fontSize": 10,
      "width": 130,
      "fontFamily": "DMSans",
      "fill": "#000000"
    }
  },
];

export default DOCSTAMP;