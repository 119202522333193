import React from 'react';
import { useRoute } from '@react-navigation/native';
import { useFetchPost } from '@src/utils/api/hooks';
import { useAuthService } from '@src/ducks/hooks';
import Loading from '@src/components/loading';
import Success from './success';
import Failed from './failed';
import _ from 'lodash';

const VerificationCheck = () => {
  const route = useRoute<any>();
  const token = route?.params?.token ?? '';
  const { onLogin } = useAuthService();
  const { data, error, runRequest } = useFetchPost();

  React.useEffect(() => {
    const routes = '/auth/confirm-registration';
    const params = {
      confirmToken: token,
    };
    runRequest(routes, params);
  }, [token]);

  const handleSuccess = () => {
    onLogin(data);
  };

  if (!_.isEmpty(error)) {
    return <Failed />;
  }

  if (!_.isEmpty(data)) {
    return <Success onPress={handleSuccess} />;
  }

  return <Loading />;
};

export default VerificationCheck;
