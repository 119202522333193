import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      flexDirection: 'row',
      backgroundColor: colors.white,
    },
    scrollView: {
      backgroundColor: colors.offwhite,
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
