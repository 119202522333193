import React, { useContext } from 'react';
import { SignInContext } from './provider';
import Login from './login';
import FaceScan from './face-scan';

const Screens = () => {
  const { screen } = useContext(SignInContext);

  switch (screen) {
    case 'face-scan':
      return <FaceScan />;
    default:
      return <Login />;
  }
};

export default Screens;
