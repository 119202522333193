import React from 'react';
import { Text, View, ViewStyle, TouchableOpacity } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import useStyles from './styles.css';
import colors from '@assets/colors';

interface UploadMaskProps {
  label: string;
  value?: string;
  type?: string;
  error?: boolean;
  style?: ViewStyle;
  onSource?: any;
}

function UploadMask(props: UploadMaskProps) {
  const styles = useStyles();

  const onSelecting = () => {
    DocumentPicker.getDocumentAsync({
      type: props.type || 'img/*',
    })
      .then((res: any) => {
        console.log('result', res);
        props.onSource(res);
      })
      .catch((e) => {
        console.log('error', e);
      });
  };

  return (
    <TouchableOpacity
      onPress={onSelecting}
      style={styles.v_upload_mask_container}
    >
      <View
        style={[
          styles.v_upload_mask_row,
          props.error && { backgroundColor: colors.red },
        ]}
      >
        <Text style={styles.t_upload_mask_plus}>+</Text>
      </View>
      <Text style={[styles.txtNoteDocs, props.error && { color: colors.red }]}>
        {props.value || props.label}
      </Text>
    </TouchableOpacity>
  );
}

export default UploadMask;
