import React, { useEffect } from 'react';
import { useRoute } from '@react-navigation/native';
import WitnessSignature from './witness-signature';
import { HeaderContext } from './provider/header';
import { ScrollView, View } from 'react-native';
import Loading from '@src/components/loading';
import useStyles from './styles.css';

// SCREENS
const Identification = React.lazy(() => import('./identification'));
const VideoSession = React.lazy(() => import('./video-session'));
const ReviewDetails = React.lazy(() => import('./witness-signature/review-send'));
const WaitingApproval = React.lazy(() => import('./waiting-approval'));
const Documents = React.lazy(() => import('./documents'));
const Review = React.lazy(() => import('./review'));

const Screens = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const routeName: any = route.name; // Get Nested Route Name
  const { activeIndex, onSetCurrentPage } = React.useContext(HeaderContext);

  const components = React.useMemo(() => {
    switch (activeIndex) {
      case 0:
        return <Identification />;
      case 1:
        return <Documents />;
      case 2:
        return <WitnessSignature />;
      case 3:
        return <ReviewDetails />;
      case 4:
        return <WaitingApproval />;
      case 5:
        return <VideoSession />;
      case 6:
        return <Review />;
    }
  }, [activeIndex]);

  useEffect(() => {
    if (routeName === 'VideoSession') {
      onSetCurrentPage(5);
    }else if (routeName === 'Review') {
      onSetCurrentPage(6);
    }
  }, [routeName]);

  return (
    <View style={styles.scrollview} >
      <React.Suspense fallback={<Loading />}>{components}</React.Suspense>
    </View>
  );
};

export default React.memo(Screens);
