import images from '@assets/images';
// import _ from 'lodash';

export const eNotary = [
  'Dashboard',
  'Inbox',
  'Transactions',
  // 'Digital Seal and Stamp',
  'Digital Notarial Book',
  'Live Notarization Requests',
  'Signatures',
  // 'Account',
  'FAQ',
  'Support',
];

export const Principal = [
  'Dashboard',
  'Transactions',
  'Inbox',
  // 'Partner Notaries', ***Hide parner Notaries Menu - Principal
  'Signatures',
  // 'Account',
  'FAQ',
  'Support',
];

export const Admin = [
  'Dashboard',
  'Applications',
  'Inbox',
  'Documents',
  'Signatures',
  'FAQ',
  'Support',
];

// let merge = _.union(eNotary, Principal);
// merge = _.union(merge, Admin);

// export const getImage: any = (label: string) => {
//   switch (label) {
//     case 'Dashboard':
//       return images.dashboard;
//     case 'Inbox':
//       return images.inbox;
//     case 'Transactions':
//     case 'Documents':
//       return images.transactions;
//     case 'Digital Seal and Stamp':
//       return images.digital_seal_stamp;
//     case 'Digital Notarial Book':
//       return images.digital_notarial_book;
//     case 'Live Notarization Requests':
//       return images.ic_liverequest;
//     case 'Partner Notaries':
//       return images.partner_notaries;
//     case 'Signatures':
//       return images.signature;
//     case 'Account':
//       return images.account;
//     case 'FAQ':
//       return images.faq;
//     case 'Support':
//       return images.support;
//     case 'Inbox':
//     default:
//       return images.dashboard;
//   }
// };

const DrawerList = (role: string) => {
  switch (role) {
    case 'admin':
      return Admin;
    case 'notary':
      return eNotary;
    default:
    case 'principal':
      return Principal;
  }
};

export default DrawerList;
