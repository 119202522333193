import React from 'react';
import { View, Image, Text } from 'react-native';
import { DEVICE_SIZES, useDeviceSize } from 'rn-responsive-styles';
import useStyles from './styles.css';
import { images } from 'theme';

const Logos = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();

  if (DEVICE_SIZES.XS === device_size || DEVICE_SIZES.SM === device_size) {
    return null;
  }

  return (
    <View style={styles.v_logo_container}>
      <Image
        resizeMode="cover"
        resizeMethod="scale"
        source={images.bg_mask}
        style={[styles.i_bg]}
      />
      <View style={styles.v_upper_logo}>
        <Image
          source={images.enotary_horizontal}
          style={styles.i_logo}
          resizeMode="contain"
        />
        <Text style={styles.txtNote3}>
          Remote notarization <br />
          at your fingertips.
        </Text>
      </View>
      <View style={styles.v_lower_logo}>
        <Image
          source={images.bg_signup_image}
          style={styles.bg_image}
          resizeMode="contain"
        />
      </View>
    </View>
  );
};

export default Logos;
