import { SagaIterator } from "@redux-saga/core";
import { call, put, takeEvery, select, delay, takeLatest } from "redux-saga/effects";
import {
  DocumentFormDataParser,
} from "@src/utils/formdata-helper";
import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";

// HELPER
import {
  completeSignatureWithCreateManifest,
  submitOnlyAffixSignature,
} from "@src/utils/signature-helper";

// API
import {
  signsecureViewDocument,
  signsecureDownloadDocument,
  signsecureSignCompleteDocument,
  getIpInfo,
  signsecureCreateDocument,
  signsecureSignDocument,
  signsecureCompleteDocument,
} from "@src/utils/api";

// Slice
import { selectedViewDocument, signsecureActions } from "../slices/signsecure.slice";
import { userActions } from "../slices/user.slice";
import { authActions, selectedAuthAccessToken, selectedAuthLoginType, selectedAuthRole } from "../slices/auth.slice";
import { CreateLiveRequestValue } from "../types";

import _ from "lodash";
import { checkPartiesSigned } from "@src/utils/filter-helper";

import Downloadjs from "downloadjs";
import DOCSTAMP from "@src/utils/mockdata/doc-stamp";


function* handleCreateLiveRequest(action: {
  type: typeof signsecureActions.createLiveRequest,
  payload: CreateLiveRequestValue,
}): SagaIterator {
  try {
    const ip = yield call(getIpInfo);
    const accessToken = yield select(selectedAuthAccessToken);
    const body = yield call(DocumentFormDataParser, action.payload, ip);
    const result = yield call(signsecureCreateDocument, body, accessToken);

    console.log("created document", result)

    yield put(signsecureActions.createLiveRequestSuccess(true));
    yield delay(1000);
    yield put(signsecureActions.createLiveRequestSuccess(false));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.createLiveRequestFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
  }
}

function* handleViewDocument(): SagaIterator {
  try {
    const role = yield select(selectedAuthRole);
    const document = yield select(selectedViewDocument);
    const accessToken = yield select(selectedAuthAccessToken);
    if(_.isEmpty(document)){
      yield put(signsecureActions.viewDocumentFailed({}));
      return;
    }

    const result = yield call(
      signsecureViewDocument,
      document.id || document._id,
      accessToken
    );
    const base64 = yield call(
      signsecureDownloadDocument,
      document.id || document._id,
      accessToken
    );

    if(!["REJECTED", "CANCELLED", "COMPLETED", "DINIED"].includes(document.workflow.status) && role === 'notary'){
      let signatures = result.data.workflow.signatures;
      signatures[signatures.length - 1] =  signatures[signatures.length - 1].concat(DOCSTAMP);
    }
    
    yield put(signsecureActions.viewDocumentSuccess({ ...result.data, uri: base64 }));
    yield put(signsecureActions.refreshDocumentRequest());
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.viewDocumentFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.viewDocumentFailed({}));
  }
}

function* refreshDocumentWorker(): SagaIterator {
  try {
    // Delay for 15 seconds before triggering the refresh again
    yield delay(15000);    
    const document = yield select(selectedViewDocument);
    const accessToken = yield select(selectedAuthAccessToken);

    if(_.isEmpty(document)){
      return;
    }

    const result = yield call(
      signsecureViewDocument,
      document.id,
      accessToken
    );

    const base64 = yield call(
      signsecureDownloadDocument,
      document.id,
      accessToken
    );
    const signatures = document.workflow.signatures;
    const payload = { ...result.data, uri: base64 };
    payload.workflow.signatures = signatures;

    yield put(signsecureActions.updateSignatures(payload));

    // Restart the token refresh process by calling the same worker saga recursively
    yield call(refreshDocumentWorker);
  } catch (error) { /* empty */ }
}

function* handleVideoSession(): SagaIterator {
  try {
    const document = yield select(selectedViewDocument);
    console.log("documentdocument", document)
    const base64 = yield call(
      submitOnlyAffixSignature,
      document.uri,
      document
    );

    const user = yield call(
      completeSignatureWithCreateManifest,
      base64,
      document
    );


    const blob = new Blob([user], { type: "application/pdf" });
    Downloadjs(
      blob,
      "files.pdf",
      "application/pdf"
    );

    yield put(signsecureActions.affixSignatureFailed({}));
    return;


    // console.log('useruser', user);

    yield put(
      signsecureActions.submitVideoSessionSuccess(
        `data:application/pdf;base64,${user}`
      )
    );
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.submitVideoSessionFailed({ message }));

    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.affixSignatureFailed({}));
  }
}

function* handleAffixSignature(): SagaIterator {
  try {
    const document = yield select(selectedViewDocument);
    const loginType = yield select(selectedAuthLoginType);
    const accessToken = yield select(selectedAuthAccessToken);

    const base64 = yield call(submitOnlyAffixSignature,
      document.uri,
      document
    );

    const ip = yield call(getIpInfo);

    // Create a Blob from the Uint8Array
    const blob = new Blob([base64], { type: "application/pdf" });
    const signature = document.signature || {};
    // Append the Blob to the FormData
    const formData = new FormData();
    formData.append("file", blob, `${document.name}.pdf`);
    formData.append("ip", ip);
    formData.append("loginType", loginType);
    formData.append("signatureType", signature.signatureType);
    formData.append("signatureId", signature.signatureId);

    const result = yield call(
      signsecureSignDocument,
      document.id,
      formData,
      accessToken
    );

    const isCompleted = yield call(checkPartiesSigned, result.data);

    if(isCompleted){
      const uri = yield call(
        signsecureDownloadDocument,
        result.data.id,
        accessToken
      );
      const base64_complete = yield call(
        completeSignatureWithCreateManifest,
        uri,
        result.data
      );
      const newblob = new Blob([base64_complete], { type: "application/pdf" });
      const formDataComplete = new FormData();
      formDataComplete.append("file", newblob, `${result.data.name}.pdf`);
      const completed = yield call(
        signsecureCompleteDocument,
        result.data.id,
        formDataComplete,
        accessToken
      );
      
      yield put(signsecureActions.affixSignatureSuccess(completed.data));
      return;
    }

    yield put(signsecureActions.affixSignatureSuccess(result.data));
  } catch (error: any) {
    console.log("error", error);
    const message = error.message || error.error || "Something went wrong";
    yield put(signsecureActions.affixSignatureFailed({ message }));
    
    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(signsecureActions.affixSignatureFailed({}));
  }
}

// Watcher Saga
function* signsecureWatcherSaga(): SagaIterator {
  yield takeEvery(signsecureActions.createLiveRequest.type, handleCreateLiveRequest);
  yield takeEvery(signsecureActions.submitVideoSession.type, handleVideoSession);
  yield takeEvery(signsecureActions.viewDocumentRequest.type, handleViewDocument);
  yield takeEvery(signsecureActions.affixSignatureRequest.type, handleAffixSignature);
  yield takeLatest(signsecureActions.refreshDocumentRequest.type, refreshDocumentWorker);
}

export default signsecureWatcherSaga;
