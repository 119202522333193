import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SignatureState {
  id: string;
  createdBy: string;
  imageType: string;
  path: string;
}

const initialState: any = {
  data: [] as SignatureState[],
  totalCount: 0,
  loading: false,
  error: null,
};

const signatureSlice = createSlice({
  name: 'signature',
  initialState,
  reducers: {
    fetchSignatureStart: (state) => {
      state.loading = true;
    },
    fetchSignatureSuccess: (state, action: PayloadAction<SignatureState>) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchSignatureFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteSignatureStart: (state) => {
      state.loading = true;
    },
    deleteSignatureSuccess: (state, action) => {
      const signatureIdToDelete = action.payload.data.id;

      state.data.data = state.data.data.filter(
        (signature: { id: string }) => signature.id !== signatureIdToDelete
      );
    },
    deleteSignatureFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addSignatureStart: (state) => {
      state.loading = true;
    },
    addSignatureSuccess: (state, action) => {
      const signatureData = action.payload.data;
      state.data.data.push(signatureData);
    },
    addSignatureFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchSignatureStart,
  fetchSignatureSuccess,
  fetchSignatureFailure,
  deleteSignatureStart,
  deleteSignatureSuccess,
  deleteSignatureFailure,
  addSignatureStart,
  addSignatureSuccess,
  addSignatureFailure,
} = signatureSlice.actions;

export default signatureSlice.reducer;
