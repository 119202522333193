import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TransactionState {
  data: {
    result: {
      attachments: Array<string>;
      createdAt: number;
      createdAtFormatted: number;
      id: string;
      message: string;
      recipient: string;
      sender: string;
      senderId: string;
      status: string;
      subject: string;
    }[];
    totalCount: number;
  };
  loading: boolean;
  error: string | null;
}

const initialState: any = {
  data: {
    result: [],
    totalCount: 0,
  },
  loading: false,
  error: null,
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    fetchTransactionStart: (state) => {
      state.loading = true;
    },
    fetchTransactionSuccess: (
      state,
      action: PayloadAction<TransactionState['data']>
    ) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchTransactionFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchTransactionStart,
  fetchTransactionSuccess,
  fetchTransactionFailure,
} = transactionSlice.actions;

export default transactionSlice.reducer;
