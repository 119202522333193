import React from 'react';
import { View } from 'react-native';
import { SignupProvider } from './SignupProvider';
import Background from './background';
import useStyles from './styles.css';
import Screens from './screens';
import Header from './header';
import Logo from './logo';

const Signup = () => {
  const styles = useStyles();

  return (
    <SignupProvider>
      <View style={styles.container}>
        <Background />
        <Header />
        <View style={styles.v_body}>
          <Screens />
          <Logo />
        </View>
      </View>
    </SignupProvider>
  );
};

export default Signup;
