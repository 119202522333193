import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';

const useStyles = CreateResponsiveStyle(
  {
    item_content_container: {
      flex: 1,
      // width: 500,
      // height: 500,
      marginLeft: 104,
      marginRight: 90,
      marginTop: 51,
      // width: '100%',
      // height: '100%',
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      item_content_container: {
        marginLeft: 40,
        marginRight: 40,
      },
    },
    [DEVICE_SIZES.SM]: {
      item_content_container: {
        marginLeft: 60,
        marginRight: 60,
      },
    },
    [DEVICE_SIZES.XS]: {
      item_content_container: {
        marginLeft: 20,
        marginRight: 20,
      },
    },
  }
);

export default useStyles;
