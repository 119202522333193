import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "60%",
      height: "15%",
      borderRadius: 5,
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: colors.white,
    },
    label: TypographyStyle.fieldsTitle,
    v_label: { flexDirection: "row", justifyContent: "space-between" },
    marginTop20: { marginTop: 20, flex: 1 },
    border: {
      marginTop: 10,
      minHeight: 51,
      borderWidth: 1,
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      borderColor: colors.border2,
    },
    t3_border: {
      marginTop: 10,
      minHeight: 51,
      borderWidth: 1,
      borderRadius: 8,
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 16,
      justifyContent: "space-between",
      borderColor: colors.border2,
    },
    t4_border: {
      marginTop: 10,
      minHeight: 51,
      borderWidth: 1,
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      borderColor: colors.border2,
    },
    errorStyle: {
      borderWidth: 2,
      borderColor: colors.red,
    },
    input: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        flex: 1,
        width: "100%",
        height: "100%",
        borderRadius: 8,
        color: colors.black3,
        paddingHorizontal: 16,
      },
    ]),
    t_input: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        borderRadius: 8,
      },
    ]),
    inputPadding: { paddingTop: 12 },
    txtError: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        fontSize: 13,
        color: colors.red,
        fontStyle: "italic",
        fontFamily: "DMSans-Bold",
      },
    ]),
    textError: {
      right: 10,
      fontSize: 10,
      color: colors.red,
      position: "absolute",
      alignSelf: "center",
      fontFamily: "DMSans",
    },
    btn_icon: { position: "absolute", width: 24, height: 24, right: 10 },
  },
  {
    [DEVICE_SIZES.LG]: {
      border: { height: 38, marginTop: 9 },
      label: { fontSize: 10.5, lineHeight: 10.5 },
      input: { fontSize: 10.5, lineHeight: 10.5 },
      t_input: { fontSize: 10.5, lineHeight: 10.5 },
      txtError: { fontSize: 10.5, lineHeight: 10.5 },
      textError: { fontSize: 10.5, lineHeight: 13.13 },
    },
    [DEVICE_SIZES.MD]: {
      input: { fontSize: 13 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      input: { fontSize: 12 },
    },
  }
);

export default useStyles;
