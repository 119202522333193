import React from 'react';
import { Text, View, Image } from 'react-native';
import { Button01 } from '@src/components/button';
import { useNavigation } from '@react-navigation/native';
import useStyles from './styles.css';
import images from '@assets/images';
import PulseAnimation from './pulse';
// import CameraScreen from './camera';

const FaceScan = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();

  return (
    <View style={styles.container}>
      <View style={styles.v_center}>
        <View style={styles.v_center_blur} />
        <View>
          <Text style={styles.t_face_recog}>Face Recognition</Text>
          <Text style={styles.t_fr_sub}>
            Scan your face to verify your identity
          </Text>
          <View style={styles.v_frame_center}>
            <Image
              style={styles.i_frame}
              source={images.scan_frame}
              resizeMode="stretch"
            />
            <PulseAnimation />
          </View>
          <Button01
            label="Get Started"
            style={styles.btn_style}
            onPress={() => navigation.navigate('Signin')}
          />
        </View>
      </View>
    </View>
  );
};

export default FaceScan;
