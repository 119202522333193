import React from 'react';
import MobileHeader from '@src/screens/drawer-screens/drawer-body/header/DrawerItemHeader';
import { mobileSizeChecker } from '@src/utils/screensize-helper';
import StepsHeader from './steps-web';

const Header = () => {
  const isMobile = mobileSizeChecker();

  if (isMobile) {
    return <MobileHeader />;
  }

  return <StepsHeader />;
};

export default React.memo(Header);
