import React from 'react';
import { View, ScrollView, useWindowDimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
// import SigningModal from './drawer-component/SigningModal';
import CreateRequestModal from './create-request-modal';
import DrawerComponent from './drawer-component';
import DrawerHeaderBody from './drawer-body';
import useStyles from './styles';
import DrawerMask from "./drawer-component/drawer-mask";

const HomeScreen = () => {
  const styles = useStyles();
  const { width, height } = useWindowDimensions();

  return (
    <View style={styles.container}>
      <SafeAreaView />
      <DrawerComponent />
      <ScrollView style={[styles.scrollView, { width, height }]}>
        <DrawerHeaderBody />
        <DrawerMask />
      </ScrollView>
      <CreateRequestModal />
      {/* <SigningModal /> */}
    </View>
  );
};

export default React.memo(HomeScreen);
