import React, { useState, useCallback, createContext, useContext } from 'react';
import { HeaderContext } from '../../provider/header';
import * as Types from './types';

interface ContextValue {
  screen: Types.ScreenProps['screen'];
  onBack: () => void;
  onNext: () => void;
  onSetScreen: (value: Types.ScreenProps['screen']) => void;
}

const WitnessScreenContext = createContext<ContextValue>({
  screen: '' as Types.ScreenProps['screen'],
  onBack: () => {},
  onNext: () => {},
} as ContextValue);

const WitnessScreenProvider = ({ children }: Types.DocumentProviderProps) => {
  const { onNext: onStepNext, onSetCurrentPage } = useContext(HeaderContext);
  const [screen, setScreen] = useState<Types.ScreenProps['screen']>(
    'witness' as Types.ScreenProps['screen']
  );

  const onBack = useCallback(() => {
    switch (screen) {
      case 'review':
        setScreen('signature');
        break;
      case 'signature':
        setScreen('witness');
        break;
      case 'witness':
        onSetCurrentPage(1);
        break;
      default:
        setScreen('signature');
        break;
    }
  }, [screen]);

  const onNext = useCallback(() => {
    switch (screen) {
      case 'witness':
        setScreen('signature');
        break;
      case 'signature':
        setScreen('review');
        break;
      case 'review':
        onStepNext();
        break;
      default:
        setScreen('signature');
        break;
    }
  }, [screen]);

  const onSetScreen = useCallback(
    (value: Types.ScreenProps['screen']) => {
      setScreen(value);
    },
    [screen]
  );

  const value = {
    screen,
    onNext,
    onBack,
    onSetScreen,
  };

  return (
    <WitnessScreenContext.Provider value={value}>
      {children}
    </WitnessScreenContext.Provider>
  );
};

export { WitnessScreenProvider, WitnessScreenContext };
