import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    drawer_item_container: {
      flex: 1,
      backgroundColor: colors.offwhite,
      overflow: 'hidden',
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
  }
);

export default useStyles;
