import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import { SignupContext } from './SignupProvider';
import useStyles from './styles.css';
import colors from '@assets/colors';
import Icon from '@assets/svg';

function BackButton() {
  const styles = useStyles();
  const { updateScreen } = React.useContext(SignupContext);
  const onBack = () => updateScreen('');

  return (
    <TouchableOpacity
      onPress={onBack}
      activeOpacity={0.9}
      style={styles.btn_style}
    >
      <Icon name="back" fill={colors.purple} />
      <Text style={styles.t_back}>Back</Text>
    </TouchableOpacity>
  );
}

export default BackButton;
