import React, { useContext } from 'react';
import { DrawerContext } from '../../DrawerProvider';
import Loading from '@src/components/loading';
import { ScrollView } from 'react-native';
import useStyles from './styles.css';
import PDFViewerScreen from "../../../../components/pdf-viewer";

// SCREENS
const Dashboard = React.lazy(() => import('screens/drawer-screens/dashboard'));
const Inbox = React.lazy(() => import('screens/drawer-screens/inbox'));
const Applications = React.lazy(
  () => import('screens/drawer-screens/applications')
);
const DocumentsPublic = React.lazy(
  () => import('screens/drawer-screens/documents')
);
const Transactions = React.lazy(
  () => import('screens/drawer-screens/transactions')
);
const Account = React.lazy(() => import('screens/drawer-screens/account'));
const DigitalSteamStamp = React.lazy(
  () => import('screens/drawer-screens/digital-seal-stamp')
);
const DigitalNotarialBook = React.lazy(
  () => import('screens/drawer-screens/digital-notarial-book')
);
const LiveNotarizationRequest = React.lazy(
  () => import('screens/drawer-screens/live-notarization-request')
);
const Faq = React.lazy(() => import('screens/drawer-screens/faq'));
const Support = React.lazy(() => import('screens/drawer-screens/support'));
const PartnerNotaries = React.lazy(
  () => import('screens/drawer-screens/partner-notaries')
);
const Signatures = React.lazy(
  () => import('screens/drawer-screens/signatures')
);

//ADMIN SCREENS
const ManageUsers = React.lazy(
  () => import('screens/drawer-screens/admin/manage-users')
);

const ManageGroups = React.lazy(
  () => import('screens/drawer-screens/admin/manage-groups')
);

const AuditLog = React.lazy(
  () => import('screens/drawer-screens/admin/audit-log')
);

const DrawerItemBody = () => {
  const styles = useStyles();
  const { activeTab } = useContext(DrawerContext);

  const renderContent = () => {
    switch (activeTab) {
      case 'Dashboard':
        return <Dashboard />;
      case 'Applications':
        return <Applications />;
      case 'Transactions':
        return <Transactions />;
      case 'Digital Seal and Stamp':
        return <DigitalSteamStamp />;
      case 'Digital Notarial Book':
        return <DigitalNotarialBook />;
      case 'Live Notarization Requests':
        return <LiveNotarizationRequest />;
      case 'Partner Notaries':
        return <PartnerNotaries />;
      case 'Signatures':
        return <Signatures />;
      case 'Account':
        return <Account />;
      case 'FAQ':
        return <Faq />;
      case 'Support':
        return <Support />;
      case 'Inbox':
        return <Inbox />;
      case 'Documents':
        return <DocumentsPublic />;
      case 'Manage Users':
        return <ManageUsers />;
      case 'Manage Groups':
        return <ManageGroups />;
      case 'Audit Log':
        return <AuditLog />;
        case 'PDF Viewer':
          return <PDFViewerScreen />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={activeTab !== 'PDF Viewer' && styles.item_content_container}
    >
      <React.Suspense fallback={<Loading />}>{renderContent()}</React.Suspense>
    </ScrollView>
  );
};

export default DrawerItemBody;
