import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put } from 'redux-saga/effects';
import {
  fetchSignatureSuccess,
  fetchSignatureFailure,
  fetchSignatureStart,
  deleteSignatureStart,
  deleteSignatureSuccess,
  deleteSignatureFailure,
  addSignatureStart,
  addSignatureSuccess,
  addSignatureFailure,
} from '../slices/signature.slice';

import {
  fetchAllSignatures,
  deleteSignatureById,
  createSignature,
} from '@src/utils/api';

function* handlefetchSignature(action: {
  payload: { accessToken: string };
}): SagaIterator {
  try {
    const { accessToken } = action.payload;
    const response: any = yield call(fetchAllSignatures, { accessToken });
    yield put(fetchSignatureSuccess(response));
  } catch (error: any) {
    yield put(fetchSignatureFailure(error.message));
    console.error('error', error);
  }
}

function* handleDeleteSignature(action: {
  payload: { accessToken: string; id: string };
}): SagaIterator {
  try {
    const { accessToken, id } = action.payload;
    const response: any = yield call(deleteSignatureById, { id, accessToken });
    console.log('delete response', response);
    yield put(deleteSignatureSuccess(response));
  } catch (error: any) {
    console.log('delete error', error);
    yield put(deleteSignatureFailure(error.message));
    console.error('error', error);
  }
}

function* handleAddSignature(action: {
  payload: { accessToken: string; formData: any };
}): SagaIterator {
  try {
    const { accessToken, formData } = action.payload;
    const response: any = yield call(createSignature, formData, accessToken);
    console.log('delete response', response);
    yield put(addSignatureSuccess(response));
  } catch (error: any) {
    console.log('delete error', error);
    yield put(addSignatureFailure(error.message));
    console.error('error', error);
  }
}

export function* signatureWatcherSaga(): SagaIterator {
  yield takeLatest(fetchSignatureStart, handlefetchSignature);
}

export function* deleteSignatureWatcherSaga(): SagaIterator {
  yield takeLatest(deleteSignatureStart, handleDeleteSignature);
}

export function* addSignatureWatcherSaga(): SagaIterator {
  yield takeLatest(addSignatureStart, handleAddSignature);
}
