import React from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import useStyles from './styles.css';

const Background = () => {
  const styles = useStyles();

  return (
    <>
      <LinearGradient
        colors={['#45459B', '#575AA7', '#575AA7', '#575AA7']}
        style={[styles.linearStyle]}
      />
    </>
  );
};

export default Background;
