import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import { StyleSheet } from 'react-native';
import colors from '@assets/colors';

export const HEADER_HEIGHT = 60;

const useStyles = CreateResponsiveStyle(
  {
    main: {
      zIndex: 2,
      width: '76%',
      height: '100%',
      marginLeft: '24%',
      position: 'absolute',
      backgroundColor: 'transparent',
    },
    container: {
      height: HEADER_HEIGHT,
      elevation: 5,
      zIndex: 4,
      paddingHorizontal: 20,
      position: 'absolute',
      shadowOpacity: 1,
      borderRadius: 8,
      shadowRadius: 12,
      paddingVertical: 5,
      backgroundColor: colors.purple2,
      shadowColor: 'rgba(82, 82, 82, 0.1)',
      shadowOffset: { height: 6, width: 0 },
      alignSelf: 'center',
      bottom: 20,
    },
    v_page_wrapper: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    t_pages: StyleSheet.flatten([
      TypographyStyle.Body1Regular,
      { color: colors.white, marginHorizontal: 30 },
    ]),
    pdf_btn_export: {
      width: 40,
      height: 40,
      borderRadius: 40 / 2,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.white,
    },

    // ZOOM
    zoom_container: {
      right: 15,
      zIndex: 4,
      top: '41%',
      alignItems: 'center',
      position: 'absolute',
      justifyContent: 'center',
    },
    pdf_btn_zoom: {
      width: 40,
      height: 40,
      borderRadius: 40 / 2,
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 0.5,
      borderColor: colors.offwhite,
      backgroundColor: colors.white,
      shadowOpacity: 1,
      shadowRadius: 12,
      paddingVertical: 5,
      shadowColor: 'rgba(82, 82, 82, 0.1)',
      shadowOffset: { height: 6, width: 0 },
    },
    divider: {
      height: 7,
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      container: {
        display: 'none',
      },
    },
    [DEVICE_SIZES.XS]: {
      container: {
        display: 'none',
      },
    },
  }
);

export default useStyles;
