import images from "@assets/images";
import Button from "@src/components/button/Button01";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectUserDisplayPhoto, selectUserFirstName } from "@src/ducks/slices/user.slice";
import { largeSize } from "@src/utils/screensize-helper";
import React, { useContext } from "react";
import { Image, Pressable, Text, View } from "react-native";
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from "react-native-reanimated";
import { DrawerContext } from "../DrawerProvider";
import Logo from "./logo";
import { DrawerComponentContext } from "./provider";
import useStyles from "./styles.css";
import { useDashboardService } from "@src/ducks/hooks";
import { tabletSize } from "@src/utils/screensize-helper";

const DrawerHeader = () => {
  const styles = useStyles();
  const isLargeSize = largeSize();
  const { onActiveTab } = useContext(DrawerContext);
  const photo = useAppSelector(selectUserDisplayPhoto);
  const firstName = useAppSelector(selectUserFirstName);
  console.log('selectUserFirstName', selectUserFirstName)
  const { animated } = useContext(DrawerComponentContext);
//   const subscriptionplan = useAppSelector(selectSubscriptionName);
  const { onDrawerEnable } = useDashboardService();
  const isMobile = tabletSize();

  const animateStyle = useAnimatedStyle(() => {
    const opacity = interpolate(
      animated.value,
      [360, 110],
      [1, 0],
      Extrapolation.CLAMP,
    );

    return {
      opacity,
    };
  }, []);

  const animatePaddingLeft = useAnimatedStyle(() => {
    const paddingLeft = interpolate(
      animated.value,
      [360, 110],
      [isLargeSize ? 13.5 : 18, isLargeSize ? 4.5 : 8],
      Extrapolation.CLAMP,
    );
    return {
      paddingLeft
    };
  }, [isLargeSize]);
  
  return (
    <View style={[styles.drawer_user_frame_container]}>
      <Logo />
      <Animated.View style={[styles.user_edit_profile_container, animatePaddingLeft]}>
        <Pressable onPress={() => onActiveTab("My Account")}>
          <Image
            source={photo ? { uri: photo } : images.placeholder_profile}
            style={styles.user_profile_picture}
            resizeMode="contain"
          />
        </Pressable>
        <Animated.View style={[styles.v_details, animateStyle]}>
          <Text style={styles.user_fullname}>Hello, {firstName}!</Text>
          <View style={styles.v_account}>
            {/* <Text style={styles.user_plan}>dada</Text> */}
            <Button
              onPress={() => 
                {
                  onActiveTab("Account");
                  if(isMobile){
                    onDrawerEnable(false) 
                  }
                }
              }
              style={[styles.btn_account]}
              labelStyle={styles.label_account}
              label="VIEW ACCOUNT"
            />
          </View>
        </Animated.View>
      </Animated.View>
    </View>
  );
};

export default DrawerHeader;