import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: "100%", 
      borderRadius: 8,
      flexDirection: "row",
      backgroundColor: colors.white,
    },
    v_pdf_wrapper: {flex: 1, alignItems: "center", height: "100%" },
    scroll_horizontal: { flexGrow: 1 },
    v_viewer: {
      alignItems: "center",
      justifyContent: "center",
    },
  },
  {
    [DEVICE_SIZES.LG]: {
    },
    [DEVICE_SIZES.MD]: {
      container: { flexDirection: "column", alignItems: "center", justifyContent: "center" },
      v_pdf_wrapper: {width: "100%",  },
    },
    [DEVICE_SIZES.SM]: {
      container: { justifyContent: "center" },
      v_pdf_wrapper: {width: "100%" },
      v_viewer: {
        width: "100%",
        marginTop: 20,
      },
      scroll_horizontal: {
        width: "100%",
        marginHorizontal: 10,
      },
    },
    [DEVICE_SIZES.XS]: {
    }
  }
);

export default useStyles;
