import { useReducer, useCallback } from 'react';

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'INPUT_CHANGE':
      return { ...state, [action.name]: action.value };
    case 'SET_INITIAL':
      return action.value;
    case 'RESET':
      return {};
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export const useInputHelper = (initialState: any = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const validateAndDispatch = (name: string, value: string, maxValue: number) => {
    const numericValue = value.replace(/\D/g, '');
    const truncatedValue = numericValue.slice(0, 2);
    if (truncatedValue !== '' && (isNaN(parseInt(truncatedValue)) || parseInt(truncatedValue) < 0 || parseInt(truncatedValue) > maxValue)) {
      return;
    }
    dispatch({ type: 'INPUT_CHANGE', name, value: truncatedValue });
  };

  const onDispatch = useCallback(
    (name: string) => (value: string) => {
      if (name === 'hours' && value === '') {
        dispatch({ type: 'INPUT_CHANGE', name, value });
        return;
      }
      if (name === 'hours' || name === 'minutes') {
        validateAndDispatch(name, value, name === 'hours' ? 12 : 59);
      } else {
        dispatch({ type: 'INPUT_CHANGE', name, value });
      }
    },
    [state]
  );

  const onSetInitial = useCallback(
    (value: any) => {
      dispatch({ type: 'SET_INITIAL', value });
    },
    [state]
  );

  const handleFormReset = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, [state]);

  return { state, onDispatch, onSetInitial, handleFormReset };
};
