const LiveRequest = [
  'Identification',
  'Submit Document',
  'Add Witnesses',
  'Review details',
  'Waiting for approval',
  'Video session',
  'Review',
];

export default LiveRequest;
