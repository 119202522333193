import React, { useContext } from 'react';
import { SignupContext } from './SignupProvider';
import SignOption from './signup-options/index';
import SignPrincipal from './signup-principal';
import SignEmailSent from './signup-emailsent';
import SignNotary from './signup-notary';

const Screens = () => {
  const { screen } = useContext(SignupContext);

  switch (screen) {
    case 'notary':
      return <SignNotary />;
    case 'principal':
      return <SignPrincipal />;
    case 'emailsent-notary':
      return <SignEmailSent notary />;
    case 'emailsent-principal':
      return <SignEmailSent />;
    default:
      return <SignOption />;
  }
};

export default Screens;
