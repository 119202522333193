import React from "react";
import { View } from "react-native";
import CreateButton from "./create-button";
import SearchHeader from "./search-header";
import useStyles from "./styles.css";
import TitleSection from "./title-section";

const WebHeader = () => {
  const styles = useStyles();

  return (
    <View style={styles.v_header_items}>
      <TitleSection />
      <SearchHeader />
      <CreateButton />
    </View>
  );
};

export default WebHeader;
