import React, { useState, useCallback, createContext } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import colors from '@assets/colors';
import * as Types from './types';

interface ContextValue {
  witnesses: Types.WitnessValue[];
  addWitness: () => void;
  updateWitness: (value: Types.WitnessValue[]) => void;
}

const WitnessContext = createContext<ContextValue>({} as ContextValue);

const initialWitness: Types.WitnessValue[] = [
  {
    id: nanoid(),
    name: '',
    email: '',
    fill: colors.signature2,
    ref: React.createRef(),
  },
  {
    id: nanoid(),
    name: '',
    email: '',
    fill: colors.signature3,
    ref: React.createRef(),
  },
];

const WitnessProvider = ({ children }: Types.ProviderProps) => {
  const [witnesses, setWitnesses] =
    useState<Types.WitnessValue[]>(initialWitness);
  const addWitness = useCallback(() => {
    const randomColor = Math.random().toString(16).substr(-6);

    setWitnesses(
      witnesses.concat([
        {
          id: nanoid(),
          name: '',
          email: '',
          type: 'withness',
          fill: `#${randomColor}`,
          ref: React.createRef(),
        },
      ])
    );
  }, [witnesses]);

  const updateWitness = useCallback(
    (value: Types.WitnessValue[]) => {
      setWitnesses(value);
    },
    [witnesses]
  );

  const value = {
    witnesses,
    addWitness,
    updateWitness,
  };

  return (
    <WitnessContext.Provider value={value}>{children}</WitnessContext.Provider>
  );
};

export { WitnessProvider, WitnessContext };
