import { memo } from 'react';
import { IconProps } from './interface';
import getSvgXML from '@assets/svg';

const Icon = (props: IconProps) => {
  return getSvgXML(props);
};

function areEqual(prevProps: any, nextProps: any) {
  return (
    prevProps.name === nextProps.name &&
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height &&
    prevProps.stroke === nextProps.stroke &&
    prevProps.fill === nextProps.fill &&
    prevProps === nextProps
  );
}
export default memo(Icon, areEqual);
