import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InboxDetailsState {
  data: any;
  loading: boolean;
  error: string | null;
}

const initialState: InboxDetailsState = {
  data: null,
  loading: false,
  error: null,
};

const inboxDetailsSlice = createSlice({
  name: 'inboxdetails',
  initialState,
  reducers: {
    fetchInboxDetailsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchInboxDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchInboxDetailsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetSelectedInbox: (state) => {
      state.data = null;
    },
  },
});

export const {
  fetchInboxDetailsStart,
  fetchInboxDetailsSuccess,
  fetchInboxDetailsFailure,
  resetSelectedInbox,
} = inboxDetailsSlice.actions;

export default inboxDetailsSlice.reducer;
