import React, { useState, useEffect } from 'react';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import { selectAuthLoginInput } from '@src/ducks/slices/auth.slice';
import { useNavigation } from '@react-navigation/native';
import { TextInput02 } from '@src/components/textinput';
import { useAppSelector } from '@src/ducks/ducksHook';
import { Button01 } from '@src/components/button';
import { useAuthService } from '@src/ducks/hooks';
import { Text, View } from 'react-native';
import useStyles from './styles.css';
import Forgot from './remember-me';
import ErrorView from './error';
import _ from 'lodash';

interface ErrorProps {
  email: string;
  password: string;
}

const Login = () => {
  const styles = useStyles();
  const errorRef = React.useRef<any>(null);
  const loginInput = useAppSelector(selectAuthLoginInput);
  const [error, setError] = useState<ErrorProps>({} as ErrorProps);
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();
  const { onSignin, isLoading, onResetLoading, setLoginInput } = useAuthService();

  const onChangeInput = (type: string) => (value: any) => {
    setLoginInput({ type, value });
    setError({} as ErrorProps);
    errorRef.current?.onClear();
  };

  const onSigningIn = () => {
    let newError: any = {};
    if (!loginInput.email) {
      newError.email = 'Email is required.';
    }
    if (!loginInput.password) {
      newError.password = 'Password is required.';
    }

    setError(newError);

    if (_.isEmpty(newError)) {
      onSignin();
    }
  };

  useEffect(() => {
    onResetLoading();
  }, []);

  return (
    <View style={styles.v_login}>
      <View style={styles.signInCenter}>
        <Text style={styles.txtNote1}>Welcome back</Text>
        <Text style={styles.txtNote2}>Enter your email and password below</Text>
      </View>
      <ErrorView ref={errorRef} />
      <View style={styles.v_field}>
        <TextInput02
          label="Email"
          error={error.email}
          value={loginInput.email}
          placeholder="Enter your email"
          onChangeText={onChangeInput("email")}
        />
        <TextInput02
          hasPassword
          label="Password"
          error={error.password}
          value={loginInput.password}
          placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
          onChangeText={onChangeInput("password")}
        />
        <Forgot 
          isCheck={loginInput?.rememberme}
          onCheck={() => onChangeInput("rememberme")(!loginInput?.rememberme)} />
        <Button01
          label="Sign In"
          color={'white'}
          onPress={onSigningIn}
          isLoading={isLoading}
          style={styles.btn_signin}
          labelStyle={styles.lbl_signin}
        />
        <Text style={styles.t_signup}>
          Don’t have an account yet?{' '}
          <Text style={styles.t_signup_2} onPress={() => navigation.navigate('Signup')}>Sign up here</Text>
        </Text>
      </View>
    </View>
  );
};

export default Login;
