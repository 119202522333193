import React from 'react';
import { fetchLiveNotarizationRequestStart } from '../slices/livenotarizationrequest.slice';
import { useAppDispatch } from '../ducksHook';

type LiveNotarizationRequestOperators = {
  fetchAllLiveNotarizationRequests: () => void;
};

export const useLiveNotarizationRequestService = (): Readonly<LiveNotarizationRequestOperators> => {
  const dispatch = useAppDispatch();

  return {
    fetchAllLiveNotarizationRequests: React.useCallback(() => {
      dispatch(fetchLiveNotarizationRequestStart());
    }, [dispatch]),
  };
};

export default useLiveNotarizationRequestService;
