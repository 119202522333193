import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { NavigationScreenProps } from '@src/navigators/NavigatorTypes';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { useNavigation } from '@react-navigation/native';
import useStyles from './styles.css';

interface ForgotProps {
  isCheck: boolean;
  onCheck: () => void;
}

const RememberMe = ({ isCheck, onCheck }: ForgotProps) => {
  const styles = useStyles();
  const navigation = useNavigation<NavigationScreenProps<'Home'>>();
  const onForgot = () => navigation.navigate('Forgot');

  return (
    <View style={styles.v_remember_forgot}>
      <TouchableOpacity
        activeOpacity={0.9}
        style={styles.v_wrap_remember}
        onPress={onCheck}
      >
        <View
          style={[
            styles.v_remember_box,
            isCheck && styles.v_remember_box_active,
          ]}
        >
          <Icon name="check" size={14} color="white" />
        </View>
        <Text style={styles.t_remember_me}>Remember me</Text>
      </TouchableOpacity>
      <Text
        suppressHighlighting
        onPress={onForgot}
        style={styles.t_forgot_password}
      >
        Forgot Password
      </Text>
    </View>
  );
};

export default RememberMe;
