import React from 'react';
import PasswordChanged from './password-changed';
import ResetPassword from './reset-password';
import { useForgotService } from '@src/ducks/hooks';

const Screens = () => {
  const { resetSuccess } = useForgotService();

  if (resetSuccess) {
    return <PasswordChanged />;
  }

  return <ResetPassword />;
};

export default Screens;
