import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import {
  fetchLiveNotarizationRequestSuccess,
  fetchLiveNotarizationRequestFailure,
  fetchLiveNotarizationRequestStart,
} from '../slices/livenotarizationrequest.slice';

import { fetchAllLiveNotarizationRequests } from '@src/utils/api';
import { authActions, selectedAuthAccessToken } from '../slices/auth.slice';
import { userActions } from '../slices/user.slice';

function* handlefetchLiveNotarizationRequest(): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthAccessToken)
    const response: any = yield call(fetchAllLiveNotarizationRequests, accessToken);
    console.log("responseresponse", response)
    yield put(fetchLiveNotarizationRequestSuccess(response.data));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    yield put(fetchLiveNotarizationRequestFailure({ message }));
    
    if (message.includes("Unauthorized")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
    yield delay(1000);
    yield put(fetchLiveNotarizationRequestFailure({}));
  }
}

export function* liveNotarizationRequestWatcherSaga(): SagaIterator {
  yield takeLatest(fetchLiveNotarizationRequestStart, handlefetchLiveNotarizationRequest);
}
