import React, { useContext } from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { Button02 } from '@src/components/button';
import {
  RenderCurrentPageLabelProps,
  RenderGoToPageProps,
} from '@react-pdf-viewer/page-navigation';
import { WitnessScreenContext } from '../provider/screen';
import { HeaderContext } from '../../provider/header';
import { PluginContext } from '../provider/plugin';
import { CanvasContext } from '../provider/canvas';
import { View, Text } from 'react-native';
import useStyles from './styles.css';
import colors from '@assets/colors';
import PDFZoom from './zoom';
import _ from 'lodash';

const PDFPages = () => {
  const styles = useStyles();
  const { canvas } = useContext(CanvasContext);
  const { activeIndex } = useContext(HeaderContext);
  const { screen } = useContext(WitnessScreenContext);
  const { pageNavigationPluginInstance } = useContext(PluginContext);
  const { CurrentPageLabel, GoToNextPage, GoToPreviousPage } =
    pageNavigationPluginInstance;

  if (_.isEmpty(canvas) || screen !== 'signature' || activeIndex !== 2) {
    return null;
  }

  return (
    <View pointerEvents="box-none" style={styles.main}>
      <View style={styles.container}>
        <View style={styles.v_page_wrapper}>
          <GoToPreviousPage>
            {({ onClick }: RenderGoToPageProps) => (
              <Button02 onPress={onClick} style={styles.pdf_btn_export}>
                <MaterialIcons
                  name="chevron-left"
                  color={colors.purple}
                  size={25}
                />
              </Button02>
            )}
          </GoToPreviousPage>
          <CurrentPageLabel>
            {({ currentPage, numberOfPages }: RenderCurrentPageLabelProps) => (
              <Text selectable={false} style={styles.t_pages}>
                Page {`${currentPage + 1} / ${numberOfPages}`}
              </Text>
            )}
          </CurrentPageLabel>
          <GoToNextPage>
            {({ onClick }: RenderGoToPageProps) => (
              <Button02 onPress={onClick} style={styles.pdf_btn_export}>
                <MaterialIcons
                  name="chevron-right"
                  color={colors.purple}
                  size={25}
                />
              </Button02>
            )}
          </GoToNextPage>
        </View>
      </View>
      <PDFZoom />
    </View>
  );
};

export default React.memo(PDFPages);
