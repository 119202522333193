import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      alignItems: 'center',
      justifycontent: 'center',
      marginBottom: 24,
    },
    i_notary: { width: 300, height: 80 },
    t_notary_notes: {
      fontSize: 21,
      textAlign: 'center',
      color: colors.white,
      fontFamily: 'DMSans-Bold',
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      i_notary: { width: 360, height: 80 },
    },
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      i_notary: { width: 152, height: 80, marginTop: 35 },
    },
    [DEVICE_SIZES.XS]: {
      i_notary: { width: 152, height: 70, marginTop: 35 },
      t_notary_notes: {
        fontSize: 20,
      },
    },
  }
);

export default useStyles;
