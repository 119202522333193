export const AdminList = [
  'Manage Users',
  // 'Manage Groups',
  'Audit Log',
  // 'Settings',
];

export const UsersTable = [
  {
    name: 'MariTest',
    email: 'maritest@test.test',
    position: 'Tester',
    role: 'Tester',
  },
  {
    name: 'Admin A',
    email: 'admin@admin.test',
    position: 'Admin',
    role: 'admin',
  },
  {
    name: 'Kuyaboy',
    email: 'kuyaboy@user.com',
    position: 'User',
    role: 'user',
  },
  {
    name: 'Jerick',
    email: 'jerickpogi@user.com',
    position: 'User',
    role: 'developer',
  },
  {
    name: 'Ategirl',
    email: 'ategirl@user.com',
    position: 'User',
    role: 'user',
  },
];

export const GroupsTable = [
  {
    group: 'Admin',
    memberNumbers: 2,
    dateAdded: 'March 6, 2023',
  },
  {
    group: 'Developer',
    memberNumbers: 2,
    dateAdded: 'March 6, 2023',
  },
  {
    group: 'Tester',
    memberNumbers: 4,
    dateAdded: 'April 6, 2023',
  },
  {
    group: 'User',
    memberNumbers: 5,
    dateAdded: 'April 23, 2023',
  },
  {
    group: 'Friends and Family',
    memberNumbers: 5,
    dateAdded: 'May 1, 2023',
  },
];

export const AuditTable = [
  {
    date: '28 Apr 2023, 01:27:36 pm',
    user: 'admin',
    type: 'documents',
    charges: 'Updated file details - [Business.pdf]',
  },
  {
    date: '28 Apr 2023, 01:27:36 pm',
    user: 'admin',
    type: 'documents',
    charges: 'Updated file details - [Business.pdf]',
  },
  {
    date: '28 Apr 2023, 01:27:36 pm',
    user: 'admin',
    type: 'documents',
    charges: 'Updated file details - [Business.pdf]',
  },
  {
    date: '28 Apr 2023, 01:27:36 pm',
    user: 'admin',
    type: 'documents',
    charges: 'Updated file details - [Business.pdf]',
  },
  {
    date: '28 Apr 2023, 01:27:36 pm',
    user: 'admin',
    type: 'documents',
    charges: 'Updated file details - [Business.pdf]',
  },
];
