import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import { StyleSheet } from 'react-native';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorPrimary,
    },
    v_height_top: { height: '20%' },
    v_body: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
    },
    v_signup: {
      width: '55%',
      justifyContent: 'center',
      paddingHorizontal: '10%',
      backgroundColor: colors.white,
    },
    v_logo_container: {
      width: '45%',
      height: '100%',
      overFlow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_upper_logo: {
      width: '100%',
      height: '40.7%',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingBottom: '3%',
    },
    v_lower_logo: {
      width: '100%',
      height: '59.3%',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    signInCenter: { textAlign: 'left', marginTop: 30 },
    txtNote1: StyleSheet.flatten([
      TypographyStyle.heading2Bold,
      { textAlign: 'left', color: colors.purple },
    ]),
    txtNote3: StyleSheet.flatten([
      TypographyStyle.heading3Bold,
      { textAlign: 'center', fontSize: 30, lineHeight: 33, marginTop: 30 },
    ]),
    v_field: { marginTop: 20 },
    txtNote2: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      { marginTop: 16, textAlign: 'left' },
    ]),
    txtNoteDocs: StyleSheet.flatten([
      TypographyStyle.uploadDocsText,
      { textAlign: 'left', flex: 1, marginLeft: 10 },
    ]),
    t_publicnotary: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { textAlign: 'left', color: colors.purple, padding: '0.3em' },
    ]),
    v_wrap_row: { flexDirection: 'row' },
    v_flex1: { flex: 1 },
    v_divider: { width: 25 },
    v_agree_container: {
      marginTop: 20,
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_wrap_agree: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_agree_box: {
      width: 18,
      height: 18,
      borderWidth: 1,
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: colors.border2,
    },
    v_agree_box_active: {
      borderColor: colors.black1,
      backgroundColor: colors.black1,
    },
    t_signup: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginTop: 35,
        textAlign: 'center',
        alignSelf: 'center',
        color: colors.black3,
      },
    ]),
    t_terms: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.orange1,
        fontWeight: '700',
      },
    ]),

    t_error_message: {
      marginTop: 12,
      fontFamily: 'DMSans',
      fontSize: 13,
      color: colors.red,
      textAlign: 'center',
    },
    btn_signup: { marginTop: 30 },
    btn_face_id: { marginTop: 15, backgroundColor: colors.btnBG },
    btn_google: {
      marginTop: 15,
      backgroundColor: colors.white,
      borderWidth: 1,
      borderColor: colors.border2,
    },
    lbl_signup: {
      fontFamily: 'Montserrat-Bold',
    },
    lbl_google: {
      fontFamily: 'Montserrat-Bold',
      color: colors.gray1,
    },
    v_marginTop20: { marginTop: 20 },
    v_height: { height: 50 },
    t_signup_2: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginLeft: 50,
        color: colors.purple,
        fontFamily: 'DMSans-Bold',
      },
    ]),

    // LOGO COMPONENT
    i_logo: {
      width: 400,
      height: 100,
      alignItems: 'center',
      justifyContent: 'center',
    },
    bg_image: {
      width: 500,
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    bg_image2: {
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },

    //IMAGE BG
    i_bg: {
      ...StyleSheet.absoluteFillObject,
      zIndex: -1,
      width: '100%',
      marginLeft: 70,
      height: '100%',
      transform: [{ scaleX: 0.7 }],
    },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { marginBottom: 10, alignSelf: 'center', color: colors.purple },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([TypographyStyle.copyRightButton]),

    // BACKGROUND COMPONENT
    linearStyle: {
      position: 'absolute',
      zIndex: -1,
      width: '100%',
      height: '100%',
    },

    // BACK BUTTON
    btn_style: {
      marginTop: 50,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    t_back: { fontFamily: 'DMSans-Bold', color: colors.purple, fontSize: 18 },

    // FORGOT PASSWORD
    v_remember_forgot: {
      marginTop: 20,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    v_wrap_remember: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_remember_box: {
      width: 18,
      height: 18,
      borderWidth: 1,
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: colors.border2,
    },
    v_remember_box_active: {
      borderColor: colors.black1,
      backgroundColor: colors.black1,
    },
    t_remember_me: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginLeft: 5,
      },
    ]),
    t_forgot_password: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        fontFamily: 'DMSans-Bold',
        color: colors.purple,
      },
    ]),

    // ERROR
    t_error: StyleSheet.flatten([
      TypographyStyle.ButtonLRegular,
      { marginTop: 13, color: colors.red, fontSize: 15, textAlign: 'center' },
    ]),
  },
  {
    [DEVICE_SIZES.XL]: {
      v_height_top: { height: '20%' },
      i_logo: {
        width: 406,
        height: 80,
      },
      bg_image: {
        width: 800,
      },
      txtNote3: {
        fontSize: 39,
        lineHeight: 51,
      },
    },
    [DEVICE_SIZES.LG]: {
      v_height_top: { height: '12%' },
    },
    [DEVICE_SIZES.MD]: {
      i_logo: {
        width: 300,
        height: 100,
      },
      v_signup: { paddingHorizontal: '4%' },
      copyRightStyle: {
        fontSize: 12,
      },
      copyRightBtnStyle: { fontSize: 12, lineHeight: 14 },
    },
    [DEVICE_SIZES.SM]: {
      v_height_top: { height: 0 },
      i_bg: {
        transform: [{ scaleX: 1 }],
      },
      v_body: {
        width: '80%',
        height: '75%',
        alignItems: 'flex-start',
        marginHorizontal: 20,
        marginBottom: 40,
      },
      v_signup: {
        borderRadius: 8,
        minWidth: '100%',
        maxWidth: '100%',
        alignItems: 'center',
        paddingVertical: 30,
        paddingHorizontal: 30,
      },
      t_publicnotary: {
        display: 'none',
      },
      txtNote1: {
        textAlign: 'center',
        fontSize: 22,
        lineHeight: 25,
      },
      txtNote2: {
        textAlign: 'center',
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
      copyRightStyle: {
        fontSize: 11,
      },
      copyRightBtnStyle: {
        fontSize: 11,
      },
      v_wrap_row: {
        flexDirection: 'column',
      },
      btn_style: {
        display: 'none',
      },
    },
    [DEVICE_SIZES.XS]: {
      v_height_top: { height: 0 },
      i_bg: {
        transform: [{ scaleX: 1 }],
      },
      v_body: {
        width: '90%',
        height: '75%',
        alignItems: 'flex-start',
        marginHorizontal: 20,
        marginBottom: 40,
      },
      v_signup: {
        borderRadius: 8,
        minWidth: '100%',
        maxWidth: '100%',
        paddingVertical: 30,
        paddingHorizontal: 20,
      },
      t_publicnotary: {
        display: 'none',
      },
      txtNote1: {
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 20,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 14,
        lineHeight: 20,
        textAlign: 'center',
      },
      v_agree_box: {
        fontSize: 9,
        color: 'red',
      },
      copyRightStyle: {
        color: colors.black3,
        fontSize: 11,
      },
      copyRightBtnStyle: {
        fontSize: 11,
      },
      v_wrap_row: {
        flexDirection: 'column',
      },
      btn_style: {
        display: 'none',
      },
    },
  }
);

export default useStyles;
