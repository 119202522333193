import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    main: {
      height: '100%',
      backgroundColor: colors.gray3,
    },
    container: {
      flex: 1,
      backgroundColor: colors.offwhite,
    },
    scrollview: { height: '100%', backgroundColor: colors.gray3, },
    v_height40: { height: 40 },
  },
  {
    [DEVICE_SIZES.LG]: {},
  }
);

export default useStyles;
