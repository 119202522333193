import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../ducksHook';
import { selectAuthSession } from '../slices/auth.slice';
import {
  selectUserDetails,
  selectUserEmail,
  selectUserFailed,
  selectUserFullname,
  userActions,
} from '../slices/user.slice';

// Types
import { SessionValue } from '../types';

type Operators = {
  user: any;
  email: string;
  fullname: string;
  session: SessionValue;
  isSessionExpired: boolean;
  fetUserDetails: () => void;
  resetError: () => void;
};

export const useUserService = (): Readonly<Operators> => {
  const dispatch = useAppDispatch();
  const session = useAppSelector(selectAuthSession);

  return {
    session,
    user: useAppSelector(selectUserDetails),
    email: useAppSelector(selectUserEmail),
    fullname: useAppSelector(selectUserFullname),
    isSessionExpired: useAppSelector(selectUserFailed),
    fetUserDetails: useCallback(() => {
      dispatch(userActions.fetchUserDetails(session));
    }, [session, dispatch]),
    resetError: useCallback(() => {
      dispatch(userActions.resetError());
    }, [session, dispatch]),
  };
};

export default useUserService;
