import React from 'react';
import Background from '../signup/background';
import { SignInProvider } from './provider';
import useStyles from './styles.css';
import { View } from 'react-native';
import Logo from '../signup/logo';
import Screens from './screens';
import Header from './header';

const Signin = () => {
  const styles = useStyles();

  return (
    <SignInProvider>
      <View style={styles.container}>
        <Background />
        <Header />
        <View style={styles.v_body}>
          <Screens />
          <Logo />
        </View>
      </View>
    </SignInProvider>
  );
};

export default Signin;
