import React from 'react';
import { View, Text, Modal, Pressable, StyleSheet } from 'react-native';
import Icon from '@expo/vector-icons/MaterialIcons';
import colors from '@assets/colors';
import Animated, {
  withTiming,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';

type Props = {
  visible: boolean;
  message: string | null;
  onClose: () => void;
};

const SuccessTop = (props: Props) => {
  const viewref = React.useRef<any>();
  const animated = useSharedValue(-100);

  const onClose = () => {
    animated.value = withTiming(-100, { duration: 500 });
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      props.onClose();
    }, 600);
  };

  const animateStyle = useAnimatedStyle(() => {
    const translateY = animated.value;

    return {
      transform: [{ translateY }],
    };
  }, [props.message]);

  React.useEffect(() => {
    if (viewref && viewref.current) {
      animated.value = withTiming(100, { duration: 500 });
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        onClose();
      }, 3000);
    }
  }, [viewref, props.visible]);

  if (!props.visible) {
    return null;
  }

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={'none'}
      onRequestClose={onClose}
      supportedOrientations={[
        'portrait',
        'portrait-upside-down',
        'landscape',
        'landscape-left',
        'landscape-right',
      ]}
    >
      <View style={styles.container}>
        <Pressable style={styles.flex1} onPress={onClose} />
        <Animated.View
          ref={viewref}
          style={[
            styles.v_modal,
            { transform: [{ translateY: animated.value }] },
            animateStyle,
          ]}
        >
          <View style={styles.v_check}>
            <Icon name="check" size={20} color={colors.black3} />
          </View>
          <Text style={styles.t_expired}>{props.message}</Text>
        </Animated.View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 2,
    alignItems: 'center',
    backgroundColor: colors.transparent,
    ...StyleSheet.absoluteFillObject,
  },
  flex1: { flex: 1 },
  v_modal: {
    position: 'absolute',
    minHeight: 50,
    paddingHorizontal: 25,
    paddingVertical: 15,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.green,
  },
  v_check: {
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  t_expired: {
    marginLeft: 7,
    fontFamily: 'DMSans-Medium',
    fontSize: 19,
    color: colors.white,
    textAlign: 'center',
  },
  btn_okay: {
    marginLeft: 17,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default SuccessTop;
