// DUCKS pattern
import { createAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@src/ducks/store';
import { LoginInput, ErrorValue } from '../types';

export interface SignupState {
  loading: boolean;
  data: {};
  error: ErrorValue;
}

export const initialState: SignupState = {
  loading: false,
  data: {},
  error: {} as ErrorValue,
};

// Slice
// 3. Create Slice
export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    signupRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    signupSuccess: (state, action) => {
      state.data = action.payload;
      state.error = {} as ErrorValue;
      state.loading = false;
    },
    signupFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

// Actions
// 2. Create Action
export const signupActions = {
  signupRequest: createAction(
    `${signupSlice.name}/signupRequest`,
    (params: LoginInput) => ({
      payload: params,
    })
  ),
  signupSuccess: signupSlice.actions.signupSuccess,
  signupFailure: signupSlice.actions.signupFailure,
};

// Selectors
// 4. Create Selectors
export const selectedSigningIn = (state: RootState) => state.signup.loading;
export const selectedSigningFailed = (state: RootState) => state.signup.error;
export const selectedSigningData = (state: RootState) => state.signup.data;

// Reducer
export default signupSlice.reducer;
