import React from 'react';
import { Text } from 'react-native';
import { useDeviceSize, DEVICE_SIZES } from 'rn-responsive-styles';
import { useNavigation } from '@react-navigation/native';
import useStyles from './styles.css';

const CopyRightContent = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();
  const navigation = useNavigation<any>();

  // Show Footer for all device
  // if (DEVICE_SIZES.XS === device_size) {
  //   return null;
  // }

  return (
    <Text style={styles.copyRightStyle}>
      <Text style={styles.copyRightBtnStyle} onPress={() => navigation.navigate("terms-condition")}>Terms & Conditions</Text> |
      <Text style={styles.copyRightBtnStyle} onPress={() => navigation.navigate("privacy-policy")}> Privacy Policy</Text>
      {'\n© 2024 '}
      <Text style={styles.copyRightBtnStyle}>UNAWA</Text>. All rights reserved
    </Text>
  );
};

export default CopyRightContent;
