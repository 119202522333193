import React from 'react';
import { WitnessScreenContext } from './provider/screen';
import { View, StyleSheet } from 'react-native';
import Loading from '@src/components/loading';

// SCREENS
const AddWitness = React.lazy(() => import('./add-witness'));
const Signatures = React.lazy(() => import('./signatures'));

const WitnessSignature = () => {
  const { screen, onSetScreen } = React.useContext(WitnessScreenContext);

  React.useEffect(() => {
    onSetScreen('witness');
  }, []);

  const components = React.useMemo(() => {
    switch (screen) {
      case 'witness':
        return <AddWitness />;
      case 'signature':
        return <Signatures />;
      default:
        return <AddWitness />;
    }
  }, [screen]);

  return (
    <View style={styles.container}>
      <React.Suspense fallback={<Loading />}>{components}</React.Suspense>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
});

export default React.memo(WitnessSignature);
