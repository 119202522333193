import React from 'react';
import { RequestProvider } from './RequestProvider';
import { DrawerProvider } from './DrawerProvider';
import HomeScreen from './HomeScreen';

const DrawerScreen = () => {
  return (
    <DrawerProvider>
      <RequestProvider>
        <HomeScreen />
      </RequestProvider>
    </DrawerProvider>
  );
};

export default DrawerScreen;
