import React, { useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import { SignupContext } from '../SignupProvider';
import { Text, View } from 'react-native';
import useStyles from './styles.css';
import SignupCard from './card';

const SignupOptions = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { updateScreen } = useContext(SignupContext);

  return (
    <View style={styles.v_signup}>
      <View style={styles.signInCenter}>
        <Text style={styles.txtNote1}>Create an account</Text>
        <Text style={styles.txtNote2}>Select an option below:</Text>
      </View>
      <View style={styles.v_field}>
        <View style={styles.v_flexdirection}>
          <SignupCard
            icon={'users'}
            title="I want to sign up as a notary public"
            onPress={() => updateScreen('notary')}
          />
          <View style={styles.v_cardseparator} />
          <SignupCard
            icon={'document'}
            title="I want to have a document notarized"
            onPress={() => updateScreen('principal')}
          />
        </View>
        <Text style={styles.t_signup}>
          Already have an account?
          <Text
            onPress={() => navigation.navigate('Signin')}
            style={styles.t_terms}
          >
            {' '}
            Login
          </Text>
        </Text>
      </View>
    </View>
  );
};

export default SignupOptions;
