import { useEffect } from 'react';
import type { RootState } from '@src/ducks/store';
import { useAuthService } from '@src/ducks/hooks';

import {
  fetchSignatureStart,
  deleteSignatureStart,
  addSignatureStart,
} from '../slices/signature.slice';

import { useAppDispatch, useAppSelector } from '../ducksHook';

type SignatureOperators = {
  signatures: any[];
  signatureLoading: boolean;
  signatureError: string | null;
  fetchAllSignatures: () => void;
  deleteSignature: (signature: any) => void;
  addSignature: (signature: any) => void;
};

export const useSignatureService = (): Readonly<SignatureOperators> => {
  const { accessToken } = useAuthService();
  const dispatch = useAppDispatch();

  const signatures = useAppSelector(
    (state: RootState) => state.signature.data
  ) as any;

  const signatureLoading = useAppSelector(
    (state: RootState) => state.signature.loading
  );
  const signatureError = useAppSelector(
    (state: RootState) => state.signature.error
  );

  const fetchAllSignatures = () => {
    dispatch(fetchSignatureStart({ accessToken } as any));
  };

  const addSignature = (formData: any) => {
    dispatch(addSignatureStart({ formData, accessToken } as any));
  };

  const deleteSignature = (signature: any) => {
    dispatch(deleteSignatureStart({ id: signature.id, accessToken } as any));
  };

  return {
    signatures,
    signatureLoading,
    signatureError,
    fetchAllSignatures,
    deleteSignature,
    addSignature,
  };
};

export default useSignatureService;
