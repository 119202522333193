import { useAppSelector } from "@src/ducks/ducksHook";
import { useDashboardService } from "@src/ducks/hooks";
import { selectedDrawerStatus } from "@src/ducks/slices/dashboard.slice";
import { tabletSize } from "@src/utils/screensize-helper";
import React from "react";
import { Pressable } from "react-native";
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import useStyles from "./styles.css";

const AnimatedButton = Animated.createAnimatedComponent(Pressable);

const DrawerMask = () => {
  const styles = useStyles();
  const isTabletSize = tabletSize();
  const animated = useSharedValue(0);
  const { onDrawerEnable } = useDashboardService();
  const drawerEnable = useAppSelector(selectedDrawerStatus);

  const animateOpacity= useAnimatedStyle(() => {
    return {
      opacity: animated.value
    };
  }, []);

  React.useEffect(() => {
    animated.value = withTiming(drawerEnable ? 1 : 0, { duration: 400 });
  }, [drawerEnable]);

  if(!isTabletSize || !drawerEnable){
    return null;
  }

  return (
    <AnimatedButton
      onPress={() => onDrawerEnable(false)}
      style={[styles.drawer_mask, animateOpacity]} />
  );
};

export default DrawerMask;
