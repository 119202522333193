import React from 'react';
import { useWindowDimensions } from 'react-native';
import { useDeviceSize, DEVICE_SIZES, minSize, maxSize } from 'rn-responsive-styles';

export const mobileSizeChecker = () => {
  const device_size = useDeviceSize();

  const isMobile =
    device_size === DEVICE_SIZES.XS || device_size === DEVICE_SIZES.SM;

  return isMobile;
};

export const tabletSize = () => {
  const device_size = useDeviceSize();

  return maxSize(DEVICE_SIZES.MD).includes(device_size);
};

export const desktopSize = () => {
  const device_size = useDeviceSize();

  return minSize(DEVICE_SIZES.LG).includes(device_size);
};

export const headerSize = () => {
  const device_size = useDeviceSize();

  switch(device_size){
  case DEVICE_SIZES.XL:
    return 109;
  case DEVICE_SIZES.LG:
    return 81.75;
  default:
    return 60;
  }
};

export const largeSize = () => {
  const device_size = useDeviceSize();

  return device_size === DEVICE_SIZES.LG;
};

export const usePDFScale: any = (maxScale: number = 1.5) => {
  const device_size = useDeviceSize();
  const { width } = useWindowDimensions();

  const scale = React.useMemo(() => {
    switch (device_size) {
      case DEVICE_SIZES.MD:
      case DEVICE_SIZES.SM:
        return 1;
      case DEVICE_SIZES.XS:
        return (width - 40) / 612;
      case DEVICE_SIZES.LG:
      default:
        return maxScale || 1.5;
    }
  }, [device_size, width, maxScale]);

  return scale;
};
