import React from 'react';
import { useForgotService } from '@src/ducks/hooks';
import ForgotEmail from './forgot-email';
import EmailSent from './email-sent';

const Screens = () => {
  const { forgotSuccess } = useForgotService();

  if (forgotSuccess) {
    return <EmailSent />;
  }
  return <ForgotEmail />;
};

export default Screens;
