import React, { createContext } from 'react';
import {
  PageNavigationPlugin,
  pageNavigationPlugin,
} from '@react-pdf-viewer/page-navigation';
import { zoomPlugin, ZoomPlugin } from '@react-pdf-viewer/zoom';
import { printPlugin, PrintPlugin } from '@react-pdf-viewer/print';
import * as Types from './types';

interface PluginContextValue {
  pageNavigationPluginInstance: PageNavigationPlugin;
  zoomPluginInstance: ZoomPlugin;
  printPluginInstance: PrintPlugin;
}

const PluginContext = createContext<PluginContextValue>({
  pageNavigationPluginInstance: {} as PageNavigationPlugin,
  zoomPluginInstance: {} as ZoomPlugin,
  printPluginInstance: {} as PrintPlugin,
});

const PluginProvider = ({ children }: Types.DocumentProviderProps) => {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const printPluginInstance = printPlugin();
  const zoomPluginInstance = zoomPlugin();
  const value = {
    pageNavigationPluginInstance,
    printPluginInstance,
    zoomPluginInstance,
  };

  return (
    <PluginContext.Provider value={value}>{children}</PluginContext.Provider>
  );
};

export { PluginProvider, PluginContext };
