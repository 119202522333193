import React from 'react';
import { selectedCreateRequestLoading, selectedDocumentParties, selectedEnableSignature, selectedSignSecureLoadingStatus, selectedViewDocument } from '@src/ducks/slices/signsecure.slice';
import { TouchableOpacity, View } from 'react-native';
import { useSignsecureService } from '@src/ducks/hooks';
import { LiveRequestSteps } from '@src/utils/mockdata';
import { HeaderContext } from '../../provider/header';
import { useAppSelector } from '@src/ducks/ducksHook';
import { Button04, Button02 } from '@src/components/button';
import useStyles from './styles.css';
import downloadjs from 'downloadjs';
import colors from '@assets/colors';
import Icon from '@assets/svg';
import Items from './items';
import moment from 'moment';
import { disableAffixButton } from '@src/utils/validation-helper';

const getButtonLabel = (active: number) => {
  switch(active){
  case 6:
    return "Download";
  case 5:
  default:
    return "Affix Signature";
  }
};

const StepsHeader = () => {
  const styles = useStyles();
  const totalSteps = LiveRequestSteps.length;
  const document = useAppSelector(selectedViewDocument);
  const userParty = useAppSelector(selectedDocumentParties);
  const loading = useAppSelector(selectedCreateRequestLoading)
  const enableSignature = useAppSelector(selectedEnableSignature);
  const loadingLabel = useAppSelector(selectedSignSecureLoadingStatus);
  const { signDocumentRequest } = useSignsecureService();
  const { activeIndex, onBack, onSetCurrentPage } = React.useContext(HeaderContext);
  const ButtonLabel = React.useMemo(() => getButtonLabel(activeIndex), [activeIndex]);
  const disabled = React.useMemo(() => disableAffixButton(document, userParty, activeIndex), [document, userParty, activeIndex]);
  const indicatorFill = `${(activeIndex / totalSteps) * 100}%`;
  const indicatorOutline = `${(totalSteps / totalSteps) * 90}%`;

  const onItemClick = React.useCallback((value: number) => {
    onSetCurrentPage(value);
  }, []);

  const onPress = React.useCallback(async() => {
    switch(activeIndex){
    case 5:
      signDocumentRequest();
      break;
    case 6:
      downloadFile();
      break;
    }
  },[activeIndex]);

  const downloadFile = React.useCallback(() => {
    downloadjs(
      document?.uri,
      `notary-signed-${moment().format('YYYY-MM-DD hh:mm:ss A')}.pdf`,
      'application/pdf'
    );
  }, [document?.uri]);

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={onBack}
        activeOpacity={0.9}
        style={styles.btnIcon}
      >
        <Icon name="back" fill={colors.white} />
      </TouchableOpacity>
      <View style={styles.steps_container}>
        <View style={[styles.steps_outline, { width: indicatorOutline }]} />
        <View style={[styles.steps_fillLine, { width: indicatorFill }]} />
        {LiveRequestSteps.map((item, index) => {
          return (
            <Items
              key={`stepIdx${index}`}
              item={item}
              index={index}
              onPress={onItemClick}
              isActive={activeIndex >= index}
            />
          );
        })}
      </View>
    {([5,6].includes(activeIndex) && enableSignature) && 
      <Button04
        disabled={disabled}
        onPress={onPress}
        isLoading={loading}
        style={styles.btn_affix}
        labelStyle={styles.lbl_affix}
        label={loading ? loadingLabel : ButtonLabel}
      />}
    </View>
  );
};

export default React.memo(StepsHeader);
