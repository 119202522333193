import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import { StyleSheet } from 'react-native';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorPrimary,
    },
    v_body: {
      // flex: 1,
      width: '100%',
      height: '100%',
      flexDirection: 'row',
    },
    v_signup: {
      width: '55%',
      justifyContent: 'center',
      paddingHorizontal: '10%',
      backgroundColor: colors.white,
    },
    v_logo_container: {
      width: '45%',
      height: '100%',
      overFlow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_upper_logo: {
      width: '100%',
      height: '40.7%',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingBottom: '3%',
    },
    v_lower_logo: {
      width: '100%',
      height: '59.3%',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    signInCenter: { textAlign: 'left', marginTop: 30 },
    txtNote1: StyleSheet.flatten([
      TypographyStyle.heading2Bold,
      { textAlign: 'left' },
    ]),
    txtNote3: StyleSheet.flatten([
      TypographyStyle.heading3Bold,
      { textAlign: 'center', fontSize: 30, lineHeight: 33, marginTop: 30 },
    ]),
    v_field: { marginTop: 20 },
    txtNote2: StyleSheet.flatten([
      TypographyStyle.heading6Regular,
      { marginTop: 16, textAlign: 'left' },
    ]),
    txtNoteDocs: StyleSheet.flatten([
      TypographyStyle.uploadDocsText,
      // { textAlign: 'left', flex: 1, marginLeft: 10 },
      { textAlign: 'center', flex: 1, marginTop: 0 },
    ]),
    t_publicnotary: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { textAlign: 'left', color: colors.purple, padding: '0.3em' },
    ]),
    v_wrap_row: { flexDirection: 'row' , paddingRight: 5 },
    v_flex1: { flex: 1 },
    v_divider: { width: 25 },
    v_agree_container: {
      marginTop: 50,
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 130
    },
    v_wrap_agree: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_agree_box: {
      width: 18,
      height: 18,
      borderWidth: 1,
      borderRadius: 4,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: colors.border2,
    },
    v_agree_box_active: {
      borderColor: colors.black1,
      backgroundColor: colors.black1,
    },
    t_remember_me: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginLeft: 8,
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 110
      },
    ]),
    t_forgot_password: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        fontWeight: '700',
        color: colors.orange1,
      },
    ]),
    t_signup: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginTop: 35,
        textAlign: 'center',
        alignSelf: 'center',
        color: colors.black3,
      },
    ]),
    t_terms: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        color: colors.orange1,
        fontWeight: '700',
      },
    ]),

    t_error_message: {
      marginTop: 12,
      fontFamily: 'DMSans',
      fontSize: 13,
      color: colors.red,
      textAlign: 'center',
    },
    btn_signup: { marginTop: 30 },
    lbl_signup: {
      fontFamily: 'Montserrat-Bold',
    },
    v_height: { height: 50 },

    // LOGO COMPONENT
    i_logo: {
      width: 400,
      height: 100,
      alignItems: 'center',
      justifyContent: 'center',
    },
    bg_image: {
      width: 500,
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    bg_image2: {
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },

    //IMAGE BG
    i_bg: {
      ...StyleSheet.absoluteFillObject,
      zIndex: -1,
      width: '100%',
      marginLeft: 70,
      height: '100%',
      transform: [{ scaleX: 0.7 }],
    },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { marginBottom: 10, alignSelf: 'center', color: colors.purple },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([TypographyStyle.copyRightButton]),

    // BACKGROUND COMPONENT
    linearStyle: {
      position: 'absolute',
      zIndex: -1,
      width: '100%',
      height: '100%',
    },

    // Email Sent
    t_email_sent: StyleSheet.flatten([
      TypographyStyle.heading2Bold,
      { textAlign: 'center' },
    ]),
    t_email_sent_notes: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { fontSize: 20, marginTop: 16, textAlign: 'center' },
    ]),
    t_email_sent_notes2: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginTop: 35,
        fontSize: 15,
        textAlign: 'center',
        color: colors.black3,
      },
    ]),
    btn_backtologin: {
      marginTop: 30,
      // backgroundColor: 'black',
      backgroundColor: colors.lightPurple,
      marginHorizontal: 30,
    },
    t_marginTop: {
      marginTop: 25,
    },

    // UPLOAD MASK
    v_upload_mask_container: {
      // flex: 1,
      // height: 40,
      marginTop: 20,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    v_upload_mask_row: {
      width: 15,
      height: 15,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.purple,
    },
    t_upload_mask_plus: {
      fontFamily: 'Roboto',
      fontSize: 15,
      color: 'white',
    },

    // BACK BUTTON
    btn_style: {
      marginTop: 50,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    t_back: { fontFamily: 'DMSans-Bold', color: colors.purple, fontSize: 18 },
  },
  {
    [DEVICE_SIZES.XL]: {
      i_logo: {
        width: 406,
        height: 80,
      },
      bg_image: {
        width: 800,
      },
      txtNote3: {
        fontSize: 39,
        lineHeight: 51,
      },
    },
    [DEVICE_SIZES.LG]: {
      i_logo: {
        width: 406,
        height: 80,
      },
      bg_image: {
        width: 460,
      },
      txtNote3: {
        fontSize: 39,
        lineHeight: 51,
      },
    },
    [DEVICE_SIZES.MD]: {
      i_logo: {
        width: 300,
        height: 100,
      },
      v_signup: { paddingHorizontal: '4%', marginTop: 0 },
      copyRightStyle: { fontSize: 12 },
      copyRightBtnStyle: { fontSize: 12, lineHeight: 14 },
      txtNote2: {
        textAlign: 'left',
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
    },
    [DEVICE_SIZES.SM]: {
      i_bg: {
        transform: [{ scaleX: 1 }],
      },
      txtNoteDocs: StyleSheet.flatten([
        TypographyStyle.uploadDocsText,
        { textAlign: 'center', flex: 1, marginTop: 0, paddingLeft: 10 },
      ]),
      v_body: {
        width: '80%',
        height: '75%',
        alignItems: 'flex-start',
        marginHorizontal: 20,
        marginBottom: 40,
      },
      v_signup: {
        borderRadius: 8,
        minWidth: '100%',
        maxWidth: '100%',
        paddingVertical: 25,
        marginBottom: 200,
        // paddingVertical: 50,
        paddingHorizontal: 30,
      },
      t_publicnotary: {
          display: 'flex',
          justifyContent: 'center',
          fontSize: 18
      },
      txtNote1: {
        textAlign: 'center',
        fontSize: 22,
        lineHeight: 25,
      },
      txtNote2: {
        textAlign: 'center',
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
      copyRightStyle: {
        fontSize: 11,
      },
      copyRightBtnStyle: {
        fontSize: 11,
      },

      t_email_sent: {
        fontSize: 22,
        lineHeight: 25,
      },
      t_email_sent_notes: {
        fontSize: 16,
        lineHeight: 20,
      },
      t_email_sent_notes2: {
        fontSize: 12,
        lineHeight: 20,
      },

      v_wrap_row: {
        flexDirection: 'column',
      },
      v_upload_mask_container: {
        alignSelf: 'center',
      },
    },
    [DEVICE_SIZES.XS]: {
      i_bg: {
        transform: [{ scaleX: 1 }],
      },
      v_body: {
        width: '90%',
        height: '75%',
        alignItems: 'flex-start',
        marginHorizontal: 20,
        marginBottom: 40,
      },
      v_signup: {
        borderRadius: 8,
        minWidth: '100%',
        maxWidth: '100%',
        paddingVertical: 25,
        marginBottom: 200,
        // paddingVertical: 50,
        paddingHorizontal: 20,
        // height: '100%',
      },
      t_publicnotary: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 16
      },
      txtNote1: {
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 20,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 14,
        lineHeight: 20,
        textAlign: 'center',
      },
      v_agree_box: {
        fontSize: 9,
        color: 'red',
      },
      // v_remember_forgot: { flexDirection: 'column' },
      t_forgot_password: { marginTop: 20 },
      copyRightStyle: {
        color: colors.black3,
        fontSize: 11,
      },
      copyRightBtnStyle: {
        fontSize: 11,
      },

      t_email_sent: {
        fontSize: 20,
        lineHeight: 25,
      },
      t_email_sent_notes: {
        fontSize: 15,
        lineHeight: 20,
      },
      t_email_sent_notes2: {
        fontSize: 12,
        lineHeight: 20,
      },

      v_wrap_row: {
        flexDirection: 'column',
      },
      v_upload_mask_container: {
        alignSelf: 'center',
      },
    },
    
    [maxSize(DEVICE_SIZES.LG)]: {
      v_signup: { paddingHorizontal: '5%'},
      txtNote1: { fontSize: 40, textAlign: 'center' },
      t_publicnotary: { marginTop: 10, textAlign: 'center' },
      txtNote3: { marginTop: 0 },
      v_agree_container: {
        paddingLeft: 0,
        marginTop: 20
      },
      t_remember_me: {
        paddingLeft: 0,
        paddingRight: 0
      },
      v_upload_mask_container: { alignSelf: 'center' },
      txtNoteDocs: StyleSheet.flatten([
        TypographyStyle.uploadDocsText,
        { textAlign: 'center', flex: 1, marginTop: 0, paddingLeft: 10 },
      ]),
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      v_divider: {
        width: 13
      },
      v_signup: {
        marginTop: 0,
        paddingHorizontal: '4%'
      },
      v_lower_logo: {
        width: '100%',
        height: '45.3%',
        marginTop: 'auto'
      },
    },
    [maxSize(DEVICE_SIZES.SM)] : {
      txtNote1: { fontSize: 29, textAlign: 'center' }
    },
  }
);

export default useStyles;
