import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InboxState {
  data: {
    result: {
      attachments: Array<string>;
      createdAt: number;
      createdAtFormatted: number;
      id: string;
      message: string;
      recipient: string;
      sender: string;
      senderId: string;
      status: string;
      subject: string;
    }[];
    totalCount: number;
  };
  loading: boolean;
  error: string | null;
}

const initialState: InboxState = {
  data: {
    result: [],
    totalCount: 0,
  },
  loading: false,
  error: null,
};

const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    fetchInboxStart: (state) => {
      state.loading = true;
    },
    fetchInboxSuccess: (state, action: PayloadAction<InboxState['data']>) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchInboxFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchInboxStart, fetchInboxSuccess, fetchInboxFailure } =
  inboxSlice.actions;

export default inboxSlice.reducer;
