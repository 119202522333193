import React from 'react';
import Edit from '@assets/svg/ic_edit.svg';
import Text from '@assets/svg/ic_text.svg';
import Back from '@assets/svg/icon_back.svg';
import Next from '@assets/svg/icon_next.svg';
import Camera from '@assets/svg/icon_camera.svg';
import Document from '@assets/svg/icon_document.svg';
import AddFill from '@assets/svg/icon_add_fill.svg';
import Initial from '@assets/svg/ic_initial.svg';
import Designation from '@assets/svg/ic_designation.svg';
import Date from '@assets/svg/ic_date.svg';
import QRCode from '@assets/svg/ic_qr.svg';
import Pencil from '@assets/svg/ic_pencil.svg';
import Logo from '@assets/svg/ic_logo.svg';
import Users from '@assets/svg/ic_users.svg';
import AccountIcon from '@assets/svg/icon_account_icon.svg';
import SignHere from '@assets/svg/sign-here.svg';
import ArrowUpOutlined from '@assets/svg/arrow_up_outlined.svg';
import ArrowDownOutlined from '@assets/svg/arrow_down_outlined.svg';
import ZoomInlined from '@assets/svg/zoom_in_outlined.svg';
import ZoomOutlined from '@assets/svg/zoom_out_outlined.svg';
import Unawa from "./unawa.svg";
import UnawaLogo from "./unawa_logo.svg";

import Icon from '@expo/vector-icons/MaterialIcons';
import { IconProps } from '@src/components/icon/interface';

const getSvgXML = (props: IconProps) => {
  const { name, width = 24, height = 24 } = props;
  switch (name) {
    case 'Logo':
      return <QRCode width={width} height={height} {...props} />;
    case 'qr':
      return <Logo width={width} height={height} {...props} />;
    case 'pencil':
      return <Pencil width={width} height={height} {...props} />;
    case 'date':
      return <Date width={width} height={height} {...props} />;
    case 'designation':
      return <Designation width={width} height={height} {...props} />;
    case 'initial':
      return <Initial width={width} height={height} {...props} />;
    case 'text':
      return <Text width={width} height={height} {...props} />;
    case 'edit':
      return (
        <Edit
          width={width}
          height={height}
          stroke={props.stroke || '#262E37'}
        />
      );
    case 'back':
      return <Back width={width} height={height} {...props} />;
    case 'next':
      return <Next width={width} height={height} {...props} />;
    case 'camera':
      return <Camera width={width} height={height} {...props} />;
    case 'document':
      return <Document width={width} height={height} {...props} />;
    case 'addFill':
      return <AddFill width={width} height={height} {...props} />;
    case 'users':
      return <Users width={width || 29} height={height || 29} {...props} />;
    case 'account_icon':
      return <AccountIcon {...props} />;
    case "SignHere":
      return <SignHere width={width || 147} height={height || 38} {...props} />;
    case "arrow_up_outlined":
      return <ArrowUpOutlined {...props} />;
    case "arrow_down_outlined":
      return <ArrowDownOutlined {...props} />;
    case "zoom_in_outlined":
      return <ZoomInlined {...props} />;
    case "zoom_out_outlined":
      return <ZoomOutlined {...props} />;
    case "Unawa":
      return <Unawa width={width || 270} height={height || 56} fill={props.fill || "white"} />;
    case "UnawaLogo":
      return <UnawaLogo width={width || 42} height={height || 49} fill={props.fill || "white"} />;
    default:
      return <Icon name={props.name} color={props.color} size={props.size} />;
  }
};

export default getSvgXML;
