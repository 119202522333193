import React, { createContext, useCallback, useState } from 'react';
import * as Types from './types';

interface CanvasContextValue {
  canvas: Types.CanvasProps;
  onUpdateCanvas: (item: Types.CanvasProps) => void;
}

export const FONT_SIZE = 15;
export const IMAGE_SIZE = 20;
export const LAYER_WIDTH = 130;
export const LAYER_HEIGHT = 50;

const CanvasContext = createContext<CanvasContextValue>(
  {} as CanvasContextValue
);

const CanvasProvider = ({ children }: Types.DocumentProviderProps) => {
  const [canvas, setCanvas] = useState<Types.CanvasProps>(
    {} as Types.CanvasProps
  );
  const onUpdateCanvas = useCallback(
    (item: Types.CanvasProps) => {
      setCanvas(item);
    },
    [canvas]
  );

  const value = {
    canvas,
    onUpdateCanvas,
  };

  return (
    <CanvasContext.Provider value={value}>{children}</CanvasContext.Provider>
  );
};

export { CanvasProvider, CanvasContext };
