import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import TypographyStyle from '@assets/typography';
import { StyleSheet } from 'react-native';
import colors from '@assets/colors';

const useStyles = CreateResponsiveStyle(
  {
    cr_modal_container: {
      position: 'absolute',
      flex: 1,
      width: '100%',
      height: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.blurBG,
    },
    cr_field_v: {
      width: 780,
      height: 477,
      backgroundColor: colors.white,
      paddingHorizontal: 64,
      paddingTop: 60,
      paddingBottom: 48,
      borderRadius: 10,
    },
    cr_header: StyleSheet.flatten([TypographyStyle.heading4Bold]),
    cr_card_v: {
      flex: 1,
      marginVertical: 52,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cr_btn_cancel: {
      height: 45,
      width: '30%',
      borderWidth: 1,
      borderRadius: 8,
      borderColor: colors.purple,
      backgroundColor: colors.white,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    cr_btn_label: StyleSheet.flatten([
      TypographyStyle.buttonText,
      { color: colors.purple },
    ]),
    cr_card_container: {
      width: '48%',
      height: '100%',
      paddingTop: 39,
      borderRadius: 6,
      borderWidth: 1,
      borderColor: colors.border2,
      margin: 8,
    },
    cr_card_text_v: { flex: 1, flexDirection: 'row', flexWrap: 'wrap' },
    cr_card_icon: { marginLeft: 29, marginRight: 11, height: 36, width: 36 },
    cr_flex1: { flex: 1 },
    cr_card_title: StyleSheet.flatten([TypographyStyle.Heading5Bold]),
    cr_i_next: { marginTop: 8, marginLeft: 6 },
    cr_card_desc: {
      fontFamily: 'DMSans',
      fontWeight: '400',
      lineHeight: 24,
      fontSize: 13,
      color: colors.lightBlack,
    },
    cr_card_btn: {
      marginTop: 25,
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 32,
      paddingRight: 10,
      backgroundColor: colors.lilac,
    },
    cr_card_btn_label: StyleSheet.flatten([
      TypographyStyle.buttonText,
      { fontFamily: 'DMSans' },
    ]),
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
