import React, { useContext } from 'react';
import { TouchableOpacity, View, Image } from 'react-native';
import { selectedDrawerStatus } from "@src/ducks/slices/dashboard.slice";
// import { DrawerContext } from '../../DrawerProvider';
import { useDashboardService } from "@src/ducks/hooks";
import { useAppSelector } from "@src/ducks/ducksHook";
import Icon from '@expo/vector-icons/Feather';
import useStyles from './styles.css';
import images from '@assets/images';
import colors from '@assets/colors';

const MobileHeader = () => {
  const styles = useStyles();
  const { onDrawerEnable } = useDashboardService();
  const drawerEnable = useAppSelector(selectedDrawerStatus);
  // const { onDrawerStatus } = useContext(DrawerContext);

  return (
    <View style={styles.drawer_mobile_container}>
      <Image
        source={images.enotary_horizontal_color}
        style={styles.drawer_mobile_logo}
        resizeMode="contain"
      />
      <TouchableOpacity onPress={() => onDrawerEnable(!drawerEnable)}>
        <Icon name="menu" size={30} color={colors.white} />
      </TouchableOpacity>
      {/* <TouchableOpacity onPress={onDrawerStatus}>
        <Icon name="menu" size={24} color={colors.white} />
        <View style={styles.icon_notif} />
      </TouchableOpacity> */}
    </View>
  );
};

export default MobileHeader;
