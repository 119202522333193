import colors from "@assets/colors";
import DrawerIcon from "@assets/svg/drawer";
import { largeSize } from "@src/utils/screensize-helper";
import React from "react";
import { Pressable, Image } from "react-native";
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from "react-native-reanimated";
import { DrawerComponentContext } from "../provider";
import useStyles from "./styles.css";

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const DrawerItem = (props: any) => {
  console.log('dadaprops', props)
  const styles = useStyles();
  const isLargeSize = largeSize();
  const activeBG = props.isActive && styles.activeBG;
  const activeHover = props.isHover && styles.activeHover;
  const activeText = props.isActive && {color: colors.purple};
  const { animated } = React.useContext(DrawerComponentContext);

  const icon_size = React.useMemo(() => {
    if(isLargeSize){
      switch(props.item){
      case "Dashboard":
        return { width: 16, height: 15};
      case "Inbox":
        return { width: 15, height: 11.25};
      case "Transactions":
        return { width: 14.25, height: 16.44};
      case "Documents":
        return { width: 15, height: 16.5};
      case "Digital Seal and Stamp":
        return { width: 17.25, height: 15.75};
      case "Digital Notarial Book":
        return { width: 15.15, height: 15.15};
      case "Live Notarization Requests":
        return { width: 18, height: 18};
      case "Signatures":
        return { width: 15, height: 20.94};
      case "FAQ":
        return { width: 18, height: 18};
      case "Support":
        return { width: 18, height: 18};
      case "Inbox":
        return { width: 18, height: 18};
      case "Applications":
        return { width: 18, height: 18};
      case "Documents":
        return { width: 18, height: 18};
      case "Manage Users":
        return { width: 18, height: 18};
      case "Manage Groups":
        return { width: 18, height: 18};
      case "Audit Log":
        return { width: 18, height: 18};
      case "Logout":
        return { width: 15, height: 20.94};
      default:
        return { width: 18, height: 18 };
      }
    }

  },[props.item, isLargeSize]);

  const animateTextStyle = useAnimatedStyle(() => {
    const opacity = interpolate(
      animated.value,
      [360, 110],
      [1, 0],
      Extrapolation.CLAMP,
    );

    return {
      opacity,
    };
  }, []);

  const animatePaddingLeft = useAnimatedStyle(() => {
    const paddingLeft = interpolate(
      animated.value,
      [360, 110],
      [isLargeSize ? 17.25 : 23, isLargeSize ? 21.39 : 29],
      Extrapolation.CLAMP,
    );
    return {
      paddingLeft
    };
  }, [isLargeSize]);
  console.log('daddada', props)

  return (
    <AnimatedPressable
      onPress={props.onPress}
      onPressIn={props.onPressIn}
      onPressOut={props.onPressOut}
      onHoverIn={props.onHoverIn}
      onHoverOut={props.onHoverOut}
      style={[styles.drawer_tab_container, animatePaddingLeft, activeBG, activeHover, props.style]}>
      <DrawerIcon name={props.item} isActive={props.isActive} {...icon_size} />
      <Animated.Text style={[styles.drawer_tab_label, activeText, animateTextStyle]}>{props.item}</Animated.Text>
    </AnimatedPressable>
  );
};

function areEqual(prevProps: any, nextProps: any) {
  return (
    prevProps.isActive === nextProps.isActive &&
    prevProps.item === nextProps.item &&
    prevProps.isHover === nextProps.isHover
  );
}

export default React.memo(DrawerItem, areEqual);
