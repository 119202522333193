import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put } from 'redux-saga/effects';
import {
  fetchInboxSuccess,
  fetchInboxFailure,
  fetchInboxStart,
} from '../slices/inbox.slice';
import {
  fetchInboxDetailsSuccess,
  fetchInboxDetailsFailure,
  fetchInboxDetailsStart,
} from '../slices/inboxdetails.slice';

import { fetchAllMessages, fetchMessageById } from '@src/utils/api';

function* handlefetchInbox(action: {
  payload: { accessToken: string };
}): SagaIterator {
  try {
    const { accessToken } = action.payload;
    const response: any = yield call(fetchAllMessages, { accessToken });
    yield put(fetchInboxSuccess(response));
  } catch (error: any) {
    yield put(fetchInboxFailure(error.message));
    console.error('error', error);
  }
}

function* handleInboxDetails(action: {
  payload: { accessToken: string; id: string };
}): SagaIterator {
  try {
    const { accessToken, id } = action.payload;
    const response: any = yield call(fetchMessageById, { accessToken, id });
    yield put(fetchInboxDetailsSuccess(response));
  } catch (error: any) {
    yield put(fetchInboxDetailsFailure(error.message));
  }
}

export function* inboxWatcherSaga(): SagaIterator {
  yield takeLatest(fetchInboxStart, handlefetchInbox);
}

export function* inboxdetailsWatcherSaga(): SagaIterator {
  yield takeLatest(fetchInboxDetailsStart, handleInboxDetails);
}
