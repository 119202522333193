import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface SignatureState {
  id: string;
  createdBy: string;
  imageType: string;
  path: string;
}

const initialState: any = {
  data: [] as SignatureState[],
  totalCount: 0,
  loading: false,
  error: null,
};

const liveNotarizationRequestSlice = createSlice({
  name: 'live notarization request',
  initialState,
  reducers: {
    fetchLiveNotarizationRequestStart: (state) => {
      state.loading = true;
    },
    fetchLiveNotarizationRequestSuccess: (state, action: PayloadAction<SignatureState>) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchLiveNotarizationRequestFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchLiveNotarizationRequestStart,
  fetchLiveNotarizationRequestSuccess,
  fetchLiveNotarizationRequestFailure,
} = liveNotarizationRequestSlice.actions;

// Selectors
export const selectedLiveNotarizationLoading = (state: RootState) => state.livenotarizationrequest.loading;
export const selectedLiveNotarizationError = (state: RootState) => state.livenotarizationrequest.error;
export const selectedLiveNotarizationData = (state: RootState) => state.livenotarizationrequest.data;

export default liveNotarizationRequestSlice.reducer;
