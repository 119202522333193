// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@src/ducks/store';

export interface AppStateType {
  screen: 'pickNotaryPublic' | 'submitNotaryPublic';
}

export const initialState: AppStateType = {
  screen: 'pickNotaryPublic',
};

// Slice
export const appStateSlice = createSlice({
  name: 'appstate',
  initialState,
  reducers: {
    setPickNotaryScreen: (state, action) => {
      state.screen = action.payload;
    },
  },
});

// Actions
export const appStateActions = {
  setPickNotaryScreen: appStateSlice.actions.setPickNotaryScreen,
};

// Selectors
export const selectedScreenPickNotary = (state: RootState) =>
  state.appstate.screen;

// Reducer
export default appStateSlice.reducer;
