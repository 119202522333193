import React from 'react';
import { Pressable, View, Text } from 'react-native';
import useStyles from './styles.css';
import colors from '@assets/colors';

type ItemProps = {
  item: string;
  index: number;
  isActive: boolean;
  onPress: (value: number) => void;
};

const Items = (props: ItemProps) => {
  const styles = useStyles();

  return (
    <Pressable
      onPress={() => props.onPress(props.index)}
      style={styles.v_align_center}
    >
      <View style={styles.outer_circle}>
        <View
          style={[
            styles.number_circle,
            props.isActive && {
              backgroundColor: colors.white,
            },
          ]}
        >
          <Text style={styles.steps_number}>{props.index + 1}</Text>
        </View>
      </View>
      <Text
        style={[
          styles.steps_label,
          props.isActive && {
            color: colors.white,
          },
        ]}
      >
        {props.item}
      </Text>
    </Pressable>
  );
};

export default Items;
