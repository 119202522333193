import { useEffect } from 'react';
import type { RootState } from '@src/ducks/store';
import { useAuthService } from '@src/ducks/hooks';

import { fetchInboxStart } from '../slices/inbox.slice';
import { fetchInboxDetailsStart } from '../slices/inboxdetails.slice';
import { resetSelectedInbox } from '../slices/inboxdetails.slice';

import { useAppDispatch, useAppSelector } from '../ducksHook';

interface InboxState {
  data: {
    html: string;
    subject: string;
    message: string;
    recipient: string;
  };
  loading: boolean;
  error: string | null;
}

type InboxOperators = {
  inboxes: any[];
  selectedInbox: InboxState;
  inboxLoading: boolean;
  inboxError: string | null;
  onReset: () => void;
  fetchAllInbox: () => void;
  fetchInbox: (inbox: any) => void;
};

export const useInboxService = (): Readonly<InboxOperators> => {
  const { accessToken } = useAuthService();
  const dispatch = useAppDispatch();

  const inboxes = useAppSelector((state: RootState) => state.inbox.data) as any;
  const selectedInbox = useAppSelector(
    (state: RootState) => state.inboxdetails.data
  );
  const inboxLoading = useAppSelector(
    (state: RootState) => state.inbox.loading
  );
  const inboxError = useAppSelector((state: RootState) => state.inbox.error);

  const fetchAllInbox = () => {
    dispatch(fetchInboxStart({ accessToken } as any));
  };

  const fetchInbox = (inbox: any) => {
    dispatch(fetchInboxDetailsStart({ id: inbox.id, accessToken } as any));
  };

  const onReset = () => {
    dispatch(resetSelectedInbox());
  };

  useEffect(() => {}, [fetchInbox]);

  return {
    inboxes,
    selectedInbox,
    inboxLoading,
    inboxError,
    fetchAllInbox,
    fetchInbox,
    onReset,
  };
};

export default useInboxService;
