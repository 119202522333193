import React, { useState, useCallback, createContext } from 'react';

interface RequestContextValue {
  isLiveActive: boolean;
  onLivePress: () => void;
  isSubmitDocumentActive: boolean;
  onSubmitDocumentPress: () => void;
  isCreateRequestVisible: boolean;
  onCreateRequestPress: () => void;
  totalSteps: number;
  onSetTotalSteps: (val: number) => void;
  closeLiveHeader: () => void;
  isOnVideo: boolean;
  onSetRecordVideScreen: () => void;
  isSignModalShowing: boolean;
  onSetSignModal: () => void;
}

const RequestContext = createContext<RequestContextValue>({
  isLiveActive: false,
  onLivePress: () => {},
  isSubmitDocumentActive: false,
  onSubmitDocumentPress: () => {},
  isCreateRequestVisible: false,
  onCreateRequestPress: () => {},
  totalSteps: 0,
  onSetTotalSteps: () => {},
  closeLiveHeader: () => {},
  isOnVideo: false,
  onSetRecordVideScreen: () => {},
  isSignModalShowing: false,
  onSetSignModal: () => {},
});

interface BoardProviderProps {
  children: React.ReactElement;
}

const RequestProvider = ({ children }: BoardProviderProps) => {
  const [isSignModalShowing, setSignModalStatus] = useState(false);
  const [isLiveActive, setLiveStatus] = useState(false);
  const [isSubmitDocumentActive, setSubmitDocumentStatus] = useState(false);
  const [isCreateRequestVisible, setCreateRequestVisible] = useState(false);
  const [isOnVideo, setVideoScreenStatus] = useState(false);
  const [totalSteps, setTotalSteps] = useState(0);

  const onSetSignModal = useCallback(() => {
    setSignModalStatus(!isSignModalShowing);
  }, [isSignModalShowing]);

  const onLivePress = useCallback(() => {
    setLiveStatus(!isLiveActive);
  }, [isLiveActive]);

  const onSubmitDocumentPress = useCallback(() => {
    setSubmitDocumentStatus(!isSubmitDocumentActive);
  }, [isSubmitDocumentActive]);

  const onCreateRequestPress = useCallback(() => {
    setCreateRequestVisible(!isCreateRequestVisible);
  }, [isCreateRequestVisible]);

  const closeLiveHeader = () => {
    setLiveStatus(false);
    setSubmitDocumentStatus(false);
  };

  const onSetRecordVideScreen = useCallback(() => {
    setVideoScreenStatus(!isOnVideo);
  }, [isOnVideo]);

  const onSetTotalSteps = useCallback(
    (val: number) => {
      setTotalSteps(val);
    },
    [totalSteps]
  );

  const value = {
    isLiveActive,
    isSubmitDocumentActive,
    isCreateRequestVisible,
    onSubmitDocumentPress,
    onLivePress,
    onCreateRequestPress,
    totalSteps,
    onSetTotalSteps,
    closeLiveHeader,
    isOnVideo,
    onSetRecordVideScreen,
    isSignModalShowing,
    onSetSignModal,
  };

  return (
    <RequestContext.Provider value={value}>{children}</RequestContext.Provider>
  );
};

export { RequestProvider, RequestContext };
