import { SagaIterator } from 'redux-saga';
import { takeLatest, call, put } from 'redux-saga/effects';
import {
  fetchTransactionSuccess,
  fetchTransactionFailure,
  fetchTransactionStart,
} from '../slices/transaction.slice';

import { fetchAllTransactions } from '@src/utils/api';

function* handlefetchTransaction(action: {
  payload: { accessToken: string };
}): SagaIterator {
  try {
    const { accessToken } = action.payload;
    const response: any = yield call(fetchAllTransactions, { accessToken });
    yield put(fetchTransactionSuccess(response));
  } catch (error: any) {
    yield put(fetchTransactionFailure(error.message));
    console.error('error', error);
  }
}

export function* transactionWatcherSaga(): SagaIterator {
  yield takeLatest(fetchTransactionStart, handlefetchTransaction);
}
