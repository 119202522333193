import React from 'react';
import { enableFreeze, enableScreens } from 'react-native-screens';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { PersistGate } from 'redux-persist/integration/react';
import { BreakpointsProvider } from 'rn-responsive-styles';
import AppNavigator from './navigators/AppNavigator';
import store, { persistor } from './ducks/store';
import Splash from '@src/components/loading';
import { imageAssets } from '@assets/images';
import { fontAssets } from '@assets/fonts';
import AuthProvider from './AuthProvider';
import { Provider } from 'react-redux';

enableFreeze(true);
enableScreens(true);

const App = () => {
  const [loaded, setLoaded] = React.useState(false);
  const handleLoadAssets = async () => {
    try {
      await Promise.all([...imageAssets, ...fontAssets]);
    } catch (e) {
      console.warn(e);
    } finally {
      setLoaded(true);
    }
  };

  React.useEffect(() => {
    handleLoadAssets();
  }, []);

  if (!loaded) {
    return <Splash />;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={<Splash />} persistor={persistor}>
        <BreakpointsProvider breakpoints={[1440, 992, 850, 540]}>
          <SafeAreaProvider>
            <AuthProvider>
              <AppNavigator />
            </AuthProvider>
          </SafeAreaProvider>
        </BreakpointsProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
