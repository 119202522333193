import colors from '@assets/colors';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withRepeat,
  withDelay,
  interpolate,
  withTiming,
  Easing,
  Extrapolation,
} from 'react-native-reanimated';
import Icon from '@expo/vector-icons/MaterialIcons';

const PulseAnimation = (props: any) => {
  const scale1 = useSharedValue(1);
  const scale2 = useSharedValue(1);
  const scale3 = useSharedValue(1);

  const pulseSequence1 = withTiming(2, {
    duration: 4500,
    easing: Easing.linear,
  });

  const pulseSequence2 = withDelay(
    1500,
    withTiming(2, { duration: 4500, easing: Easing.linear })
  );

  const pulseSequence3 = withDelay(
    3000,
    withTiming(2, { duration: 4500, easing: Easing.linear })
  );

  useEffect(() => {
    scale1.value = withRepeat(pulseSequence1, -1, false);
    scale2.value = withRepeat(pulseSequence2, -1, false);
    scale3.value = withRepeat(pulseSequence3, -1, false);
  }, []);

  const animatedStyle1 = useAnimatedStyle(() => {
    const opacity = interpolate(
      scale1.value,
      [1, 2],
      [1, 0],
      Extrapolation.CLAMP
    );

    return {
      transform: [{ scale: scale1.value }],
      opacity: opacity,
    };
  });

  const animatedStyle2 = useAnimatedStyle(() => {
    const opacity = interpolate(
      scale2.value,
      [1, 2],
      [1, 0],
      Extrapolation.CLAMP
    );

    return {
      transform: [{ scale: scale2.value }],
      opacity: opacity,
    };
  });

  const animatedStyle3 = useAnimatedStyle(() => {
    const opacity = interpolate(
      scale3.value,
      [1, 2],
      [1, 0],
      Extrapolation.CLAMP
    );

    return {
      transform: [{ scale: scale3.value }],
      opacity: opacity,
    };
  });

  return (
    <>
      <Animated.View style={[styles.pulse, animatedStyle3]} />
      <Animated.View style={[styles.pulse, animatedStyle2]} />
      <Animated.View style={[styles.pulse, animatedStyle1]} />
      <View style={[styles.pulse]}>
        {props.check && <Icon name="check" size={30} color={'white'} />}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pulse: {
    width: 100,
    height: 100,
    borderRadius: 50,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.signature3,
  },
});

export default PulseAnimation;
