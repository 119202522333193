import Icon from "@expo/vector-icons/MaterialIcons";
import Button from "@src/components/button/Button04";
import { RequestContext } from '../../../RequestProvider';
import React, { useContext } from "react";
import { Text } from "react-native";
import useStyles from "./styles.css";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';

const CreateButton = () => {
  const styles = useStyles();
  const role = useAppSelector(selectedAuthRole);
  const { onCreateRequestPress } = useContext(RequestContext);

  if (role === 'principal') {
    return (
      <Button
        onPress={onCreateRequestPress}
        style={styles.btn_createdocument} >
        <>
          <Text style={styles.btn_label}>Create new request</Text>
          <Icon name="add" size={15} color="white" />
        </>
      </Button>
    );
  } else {
    return null;
  }
};

export default CreateButton;
