import React from "react";
import { Text } from "react-native";
import { DrawerContext } from "../../../DrawerProvider";
import useStyles from "./styles.css";

const TitleSection = () => {
  const styles = useStyles();
  const { activeTab } = React.useContext(DrawerContext);

  return <Text style={styles.t_title}>{activeTab}</Text>
};

export default TitleSection;
