import React from 'react';
import { View } from 'react-native';
import CopyRightContent from './copy-right';
import Background from './background';
import useStyles from './styles.css';
import Header from './header';
import Login from './login';
import Logo from './logo';

const SignIn = () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Background />
      <Header />
      <View style={styles.v_body}>
        <Logo />
        <Login />
      </View>
      <CopyRightContent />
    </View>
  );
};

export default SignIn;
