import { useEffect } from 'react';
import type { RootState } from '@src/ducks/store';
import { useAuthService } from '@src/ducks/hooks';

import { fetchTransactionStart } from '../slices/transaction.slice';

import { useAppDispatch, useAppSelector } from '../ducksHook';

type TransactionOperators = {
  transactions: any[];
  transactionLoading: boolean;
  transactionError: string | null;
  fetchAllTransactions: () => void;
};

export const useTransactionService = (): Readonly<TransactionOperators> => {
  const { accessToken } = useAuthService();
  const dispatch = useAppDispatch();

  const transactions = useAppSelector(
    (state: RootState) => state.transaction.data
  ) as any;
  const transactionLoading = useAppSelector(
    (state: RootState) => state.transaction.loading
  );
  const transactionError = useAppSelector(
    (state: RootState) => state.transaction.error
  );

  const fetchAllTransactions = () => {
    dispatch(fetchTransactionStart({ accessToken } as any));
  };

  useEffect(() => {}, [fetchAllTransactions]);

  return {
    transactions,
    transactionLoading,
    transactionError,
    fetchAllTransactions,
  };
};

export default useTransactionService;
