import React from 'react';
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import TypographyStyle from '@assets/typography';
import { Button01Types } from './types';
import { colors } from '@assets/index';

const Button = (props: Button01Types) => {
  const backgroundColor = props.disabled && {
    backgroundColor: colors.border2,
  };

  return (
    <View style={[styles.container, backgroundColor, props.style]}>
      <TouchableOpacity
        activeOpacity={0.8}
        style={styles.buttonStyle}
        onPress={props.onPress}
        disabled={props.disabled || props.isLoading}
      >
        {props.isLoading ? (
          <ActivityIndicator
            animating
            size="small"
            color={props.color || 'black'}
          />
        ) : (
          <>
            {props.source && (
              <Image
                source={props.source}
                resizeMode="contain"
                style={[styles.imgStyle, props.imgStyle]}
              />
            )}
            <Text style={[styles.labelStyle, props.labelStyle]}>
              {props.label || 'Next'}
            </Text>
          </>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 48,
    borderRadius: 8,
    paddingHorizontal: 20,
    backgroundColor: colors.lightPurple,
  },
  buttonStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgStyle: { height: 23, width: 23, marginRight: 18 },
  labelStyle: StyleSheet.flatten([
    TypographyStyle.buttonText,
    {
      color: colors.white,
    },
  ]),
});

export default React.memo(Button);
