import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { StyleSheet } from 'react-native';
import TypographyStyle from '@assets/typography';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.colorPrimary,
    },
    t_notary_notes: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { fontSize: 21, color: colors.white, fontFamily: 'DMSans-Bold' },
    ]),
    scrollview: { flex: 1, width: '100%' },
    v_body: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    v_login: {
      padding: 25,
      minWidth: 450,
      maxWidth: 650,
      minHeight: 500,
      borderWidth: 1,
      borderRadius: 8,
      paddingHorizontal: 100,
      borderColor: colors.white,
      backgroundColor: colors.white,
    },
    signInCenter: { alignItems: 'center', marginTop: 20 },
    txtNote1: StyleSheet.flatten([TypographyStyle.heading2Bold]),
    v_field: { marginTop: 20 },
    txtNote2: StyleSheet.flatten([
      TypographyStyle.heading4Regular,
      { marginTop: 16 },
    ]),
    btn_signin: {
      marginTop: 50,
      backgroundColor: colors.lightPurple,
    },
    lbl_signin: {
      fontFamily: 'Montserrat-Bold',
    },
    v_height: { height: 20 },
  },
  {
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      v_body: {
        width: '80%',
        alignItems: 'flex-start',
        marginHorizontal: 20,
        marginBottom: 40,
      },
      v_login: {
        minWidth: '100%',
        maxWidth: '100%',
        paddingHorizontal: 80,
      },
      txtNote1: {
        fontSize: 22,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
    },
    [DEVICE_SIZES.XS]: {
      v_body: {
        width: '90%',
        alignItems: 'flex-start',
        marginHorizontal: 20,
        marginBottom: 40,
      },
      v_login: {
        minWidth: '100%',
        maxWidth: '100%',
        paddingHorizontal: 20,
      },
      txtNote1: {
        fontSize: 20,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 14,
        lineHeight: 20,
      },
    },
  }
);

export default useStyles;
