import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import colors from '@assets/colors';

export const STEPS_HEADER_HEIGHT = 91;

const useStyles = CreateResponsiveStyle(
  {
    container: {
      // top: 0,
      width: '100%',
      height: STEPS_HEADER_HEIGHT,
      paddingHorizontal: 30,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.purple,
      shadowOpacity: 1,
      shadowColor: 'rgba(82, 82, 82, 0.1)',
      shadowOffset: { height: 6, width: 0 },
      paddingVertical: 5,
      shadowRadius: 12,
      elevation: 10,
    },
    btnIcon: {
      position: 'absolute',
      left: 64,
      zIndex: 2,
    },
    steps_container: {
      height: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    steps_outline: {
      flex: 1,
      top: 32,
      height: 1,
      left: '5%',
      position: 'absolute',
      justifyContent: 'center',
      backgroundColor: colors.lightPurple,
    },
    steps_fillLine: {
      flex: 1,
      top: 32,
      height: 1,
      left: '5%',
      position: 'absolute',
      justifyContent: 'center',
      backgroundColor: colors.white,
    },
    v_align_center: {
      minWidth: 120,
      alignItems: 'center',
      justifyContent: 'center',
    },
    outer_circle: {
      paddingRight: 10,
      paddingLeft: 10,
      borderRadius: 50,
      paddingVertical: 10,
      backgroundColor: colors.purple,
    },
    number_circle: {
      height: 28,
      width: 28,
      borderRadius: 20,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    steps_number: {
      fontFamily: 'DMSans',
      fontWeight: '700',
      fontSize: 14,
      lineHeight: 14,
      color: colors.lightPurple,
    },
    steps_label: {
      fontSize: 10,
      lineHeight: 17,
      fontFamily: 'DMSans-Medium',
      color: colors.lightPurple,
    },
   
    btn_affix: {
      width: 190,
      height: 48,
      position: 'absolute', right: 60,
      borderRadius: 8,
      backgroundColor: colors.lightPurple, },
    lbl_affix: {
      fontSize: 14,
      lineHeight: 16,
      fontFamily: 'Montserrat-bold',
      color: colors.white,
    },
  },
  {
    [DEVICE_SIZES.LG]: {},
  }
);

export default useStyles;
