import { CreateResponsiveStyle } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      zIndex: 1,
      height: 90,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 25,
      paddingRight: 50,
      backgroundColor: colors.purple,
      justifyContent: 'space-between',
      fontFamily: 'DMSans',
    },
    btn_style: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    btn_actions: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    page_label:{
      color: colors.white,
      fontFamily: 'DMSans',
      fontSize: 20,
      paddingRight: 20,
      paddingLeft: 20,
      lineHeight: 35
    },
    spacing: {
      padding: 8,
    },
    divider_vertical: {
      marginRight: 20,
      marginLeft: 20,
      height: 50,
      width: 1,
      backgroundColor: '#F5F5F5',
    },
    zoom: {
      marginRight: 5,
      marginLeft: 5,
      backgroundColor: '#F5F5F5',
      borderRadius: 8,
    }
  },
);

export default useStyles;
